import { Exclude, Type } from "class-transformer";
import { ProductTypeKey } from "@impacgroup/doerr-wildkamera-api-shared";

export class Imei {
    _id?: string;

    realtimeConnectionEstablished?: boolean;
    code: string = "";
    productType?: ProductTypeKey;
    version?: number;

    @Type(() => Date)
    @Exclude({ toPlainOnly: true })
    created?: Date;

    @Type(() => Date)
    @Exclude({ toPlainOnly: true })
    updated?: Date;
}
