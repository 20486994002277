<div class="content__inner">
    <header class="content__title">
        <h1 *ngIf="MODE === 'ADD'">{{ "firmwares.detail.add.title" | translate }}</h1>
        <h1 *ngIf="MODE === 'EDIT'">{{ "firmwares.detail.edit.title" | translate }}</h1>

        <div class="actions">
            <button (click)="back()" class="btn btn-secondary btn--icon-text"><i class="zmdi zmdi-arrow-back"></i> {{ "global.buttons.back" | translate }}</button>
        </div>
    </header>

    <div class="card">
        <div class="card-body">
            <form name="form" class="row" *ngIf="firmware" (ngSubmit)="f.form.valid && save()" #f="ngForm" novalidate>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "firmwares.props.name" | translate }}</label>
                        <input name="name" type="text" class="form-control" [(ngModel)]="firmware.name" #name="ngModel" [ngClass]="{ 'is-invalid': f.submitted && name.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ "firmwares.detail.validation.name" | translate }}
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "firmwares.props.productType" | translate }}</label>
                        <ng-select
                            name="productType"
                            [items]="productTypes"
                            placeholder=""
                            bindLabel="name"
                            bindValue="key"
                            [clearable]="false"
                            [(ngModel)]="firmware.productType"
                            #productType="ngModel"
                            [ngClass]="{ 'is-invalid': f.submitted && productType.invalid }"
                            required
                        >
                        </ng-select>
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ "firmwares.detail.validation.productType" | translate }}
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "firmwares.props.published" | translate }}</label>
                        <div class="row">
                            <div class="col-12">
                                <div class="toggle-switch">
                                    <input type="checkbox" class="toggle-switch__checkbox" [(ngModel)]="firmware.published" id="published" name="published" />
                                    <i class="toggle-switch__helper"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "firmwares.props.publishedBeta" | translate }}</label>
                        <div class="row">
                            <div class="col-12">
                                <div class="toggle-switch">
                                    <input type="checkbox" class="toggle-switch__checkbox" [(ngModel)]="firmware.publishedBeta" id="publishedBeta" name="publishedBeta" />
                                    <i class="toggle-switch__helper"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "firmwares.props.originalname" | translate }}</label>
                        <input name="originalname" type="text" class="form-control" [ngModel]="firmware.originalname" readonly="readonly" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="form-group">
                        <button (click)="fileInput.click()" class="btn btn-success btn--icon-text" type="button">
                            <i class="zmdi zmdi-collection-plus"></i> {{ "global.buttons.choose_file" | translate }}
                            <input #fileInput type="file" (change)="handleFileInput($event)" style="display: none" accept=".zip,.rar,.7zip" />
                        </button>
                    </div>
                </div>

                <div *ngIf="firmware.publishedBeta === true" class="col-md-12">
                    <div class="form-group">
                        <label>{{ "firmwares.detail.imeiWhitelist.title" | translate }}</label>
                        <textarea name="imeiWhitelist" rows="5" type="text" class="form-control" [(ngModel)]="imeiWhitelist" #name="ngModel" [ngClass]="{ 'is-invalid': invalidImei }"></textarea>
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ "firmwares.detail.validation.imeiWhitelist" | translate }}
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <button class="float-right btn btn-success btn--icon-text" [disabled]="f.submitted && f.invalid"><i class="zmdi zmdi-check"></i> {{ "global.buttons.save" | translate }}</button>
                </div>
            </form>
        </div>
    </div>
</div>
