import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { FullLayoutComponent } from "./layout/layouts/layout-full/layout.component";
import { SingleLayoutComponent } from "./layout/layouts/layout-single/layout.component";
import { AuthenticationGuard } from "@impacgroup/angular-oauth-base";
import { OrganizationsModule, RolesModule, UsersModule } from "@impacgroup/angular-access-management";
import { ProfileModule, ResetPasswordModule } from "@impacgroup/angular-selfservice";
import { LanguagesModule } from "@impacgroup/angular-languages";
import { MailTemplatesModule, SmtpMailAccountsModule } from "@impacgroup/angular-mailtemplates";
import { RegistrationConfigModule } from "@impacgroup/angular-registration";
import { FirebaseConfigModule, FirebaseDevicesModule, FirebaseNotificationsModule, FirebaseNotificationTemplatesModule } from "@impacgroup/angular-firebase";
import { PaypalBillingPlansModule, PaypalConfigModule, PaypalBillingAgreementsModule, KlarnaConfigModule, KlarnaAboPlansModule, KlarnaAboAgreementsModule, AppleConfigModule, GoogleConfigModule } from "@impacgroup/angular-payment";
import { WebSmsConfigModule, WebSmsNotificationsModule } from "@impacgroup/angular-sms";
import { SystemLogMessagesModule, SystemJobPerformancesModule, SystemErrorsModule } from "@impacgroup/angular-systemhealth";
import { ImeisModule } from "./modules/imeis/imeis.module";
import { CamerasModule } from "./modules/cameras/cameras.module";
import { AboUsersModule } from "./modules/abos/abousers.module";
import { FirmwaresModule } from "./modules/firmwares/firmwares.module";
import { CrashlogApplicationsModule, CrashlogMessagesModule, CrashlogErrorsModule } from "@impacgroup/angular-crashlogs";
import { TextPoolsModule } from "@impacgroup/angular-texts";
import { StolenCamerasModule } from "./modules/stolencameras/stolencameras.module";

const routes: Routes = [
    {
        path: "",
        component: FullLayoutComponent,
        canActivate: [AuthenticationGuard],
        canActivateChild: [AuthenticationGuard],
        children: [
            // Home
            {
                path: "",
                redirectTo: "home",
                pathMatch: "full"
            },
            {
                path: "home",
                loadChildren: () => import("./pages/home/home.module").then((m) => m.HomeModule)
            },
            {
                path: "usermanagement",
                children: [
                    {
                        path: "",
                        redirectTo: "users",
                        pathMatch: "full"
                    },
                    {
                        path: "users",
                        children: UsersModule.getRoutes()
                    },
                    {
                        path: "roles",
                        children: RolesModule.getRoutes()
                    },
                    {
                        path: "organizations",
                        children: OrganizationsModule.getRoutes()
                    }
                ]
            },
            {
                path: "profile",
                children: ProfileModule.getRoutes()
            },
            {
                path: "languages",
                children: LanguagesModule.getRoutes()
            },
            {
                path: "mail",
                children: [
                    {
                        path: "",
                        redirectTo: "mailaccounts",
                        pathMatch: "full"
                    },
                    {
                        path: "mailaccounts",
                        children: SmtpMailAccountsModule.getRoutes()
                    },
                    {
                        path: "mailtemplates",
                        children: MailTemplatesModule.getRoutes()
                    }
                ]
            },
            {
                path: "registrationconfig",
                children: RegistrationConfigModule.getRoutes()
            },
            {
                path: "firebase",
                children: [
                    {
                        path: "",
                        redirectTo: "firebaseconfig",
                        pathMatch: "full"
                    },
                    {
                        path: "firebaseconfig",
                        children: FirebaseConfigModule.getRoutes()
                    },
                    {
                        path: "firebasedevices",
                        children: FirebaseDevicesModule.getRoutes()
                    },
                    {
                        path: "firebasenotifications",
                        children: FirebaseNotificationsModule.getRoutes()
                    },
                    {
                        path: "firebasenotificationtemplates",
                        children: FirebaseNotificationTemplatesModule.getRoutes()
                    }
                ]
            },
            {
                path: "payment",
                children: [
                    {
                        path: "",
                        redirectTo: "appleconfig",
                        pathMatch: "full"
                    },
                    {
                        path: "appleconfig",
                        children: AppleConfigModule.getRoutes()
                    },
                    {
                        path: "googleconfig",
                        children: GoogleConfigModule.getRoutes()
                    },
                    {
                        path: "paypalconfig",
                        children: PaypalConfigModule.getRoutes()
                    },
                    {
                        path: "paypalbillingplans",
                        children: PaypalBillingPlansModule.getRoutes()
                    },
                    {
                        path: "paypalbillingagreements",
                        children: PaypalBillingAgreementsModule.getRoutes()
                    },
                    {
                        path: "klarnaconfig",
                        children: KlarnaConfigModule.getRoutes()
                    },
                    {
                        path: "klarnaaboplans",
                        children: KlarnaAboPlansModule.getRoutes()
                    },
                    {
                        path: "klarnaaboagreements",
                        children: KlarnaAboAgreementsModule.getRoutes()
                    }
                ]
            },
            {
                path: "sms",
                children: [
                    {
                        path: "",
                        redirectTo: "websmsconfig",
                        pathMatch: "full"
                    },
                    {
                        path: "websmsconfig",
                        children: WebSmsConfigModule.getRoutes()
                    },
                    {
                        path: "websmsnotifications",
                        children: WebSmsNotificationsModule.getRoutes()
                    }
                ]
            },
            {
                path: "textpools",
                children: TextPoolsModule.getRoutes()
            },
            {
                path: "systemhealth",
                children: [
                    {
                        path: "",
                        redirectTo: "logs",
                        pathMatch: "full"
                    },
                    {
                        path: "logs",
                        children: SystemLogMessagesModule.getRoutes()
                    },
                    {
                        path: "jobs",
                        children: SystemJobPerformancesModule.getRoutes()
                    },
                    {
                        path: "errors",
                        children: SystemErrorsModule.getRoutes()
                    }
                ]
            },
            {
                path: "imeis",
                children: ImeisModule.getRoutes()
            },
            {
                path: "cameras",
                children: CamerasModule.getRoutes()
            },
            {
                path: "stolencameras",
                children: StolenCamerasModule.getRoutes()
            },
            {
                path: "abousers",
                children: AboUsersModule.getRoutes()
            },
            {
                path: "firmwares",
                children: FirmwaresModule.getRoutes()
            },
            {
                path: "crashlogs",
                children: [
                    {
                        path: "",
                        redirectTo: "crashlogapplications",
                        pathMatch: "full"
                    },
                    {
                        path: "crashlogapplications",
                        children: CrashlogApplicationsModule.getRoutes()
                    },
                    {
                        path: "crashlogerrors",
                        children: CrashlogErrorsModule.getRoutes()
                    },
                    {
                        path: "crashlogmessages",
                        children: CrashlogMessagesModule.getRoutes()
                    }
                ]
            }
        ]
    },
    {
        path: "",
        component: SingleLayoutComponent,
        children: [
            {
                path: "login",
                loadChildren: () => import("./pages/login/login.module").then((m) => m.LoginModule)
            },
            {
                path: "resetpassword",
                children: ResetPasswordModule.getRoutes()
            },
            {
                path: "404",
                loadChildren: () => import("./pages/four-zero-four/four-zero-four.module").then((m) => m.FourZeroFourModule)
            },
            {
                path: "**",
                redirectTo: "/404"
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
