import { Component } from "@angular/core";
import { Location } from "@angular/common";
import { BaseComponent } from "@impacgroup/angular-baselib";
import { FirmwaresService } from "./firmwares.service";
import { FirmwareApnVM } from "./firmwareapns.viewmodel";
import { saveAs } from "file-saver";

@Component({
    selector: "app-firmwareapns",
    templateUrl: "./firmwareapns.component.html",
    styleUrls: ["./firmwareapns.component.scss"]
})
export class FirmwareApnsComponent extends BaseComponent {
    public vm: FirmwareApnVM = {
        customCSVData: "",
        customCSVDataInvalid: false
    };

    constructor(private firmwaresService: FirmwaresService, private _location: Location) {
        super();

        this.loadData();
    }

    loadData() {
        const loadCustomDataSub = this.firmwaresService.getCustomCSVData().subscribe((customCSV) => {
            if (customCSV) {
                this.vm.customCSVData = customCSV;
            }
        });
        this.subscriptions.push(loadCustomDataSub);
    }

    saveData() {
        const saveCustomDataSub = this.firmwaresService.updateCustomCSVData(this.vm.customCSVData).subscribe((customCSV) => {
            if (customCSV) {
                this.vm.customCSVData = customCSV;
            }
        });
        this.subscriptions.push(saveCustomDataSub);
    }

    downloadApns() {
        const downloadAPNsSub = this.firmwaresService.downloadAPNs().subscribe((file) => {
            if (!file) {
                return;
            }
            saveAs(file, "apns.csv");
        });
        this.subscriptions.push(downloadAPNsSub);
    }

    back() {
        this._location.back();
    }
}
