import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, BaseComponent, BaseComponentMode } from "@impacgroup/angular-baselib";
import { ImeisService } from "./imeis.service";
import { TranslateService } from "@ngx-translate/core";
import { Imei } from "../../api-models/Imei";
import { AvailableProductTypes, ProductType } from "@impacgroup/doerr-wildkamera-api-shared";

@Component({
    selector: "app-imeidetail",
    templateUrl: "./imeidetail.component.html",
    styleUrls: ["imeidetail.component.scss"]
})
export class ImeidetailComponent extends BaseComponent implements OnInit {
    private imeiId?: string;
    public imei?: Imei;

    public productTypes: ProductType[] = AvailableProductTypes;

    constructor(private route: ActivatedRoute, private imeisService: ImeisService, private router: Router, private alertService: AlertService, private translateService: TranslateService, private _location: Location) {
        super();

        this.subscriptions.push(
            this.route.params.subscribe((params) => {
                this.imeiId = params["id"];
                if (this.imeiId) {
                    this.initEditMode();
                } else {
                    this.initAddMode();
                }
            })
        );
    }

    ngOnInit(): void {}

    initAddMode() {
        this.MODE = BaseComponentMode.ADD;
        this.imei = new Imei();
    }

    initEditMode() {
        this.MODE = BaseComponentMode.EDIT;

        if (!this.imeiId) {
            return;
        }

        this.subscriptions.push(
            this.imeisService.imeidetail(this.imeiId).subscribe({
                next: (result) => {
                    this.imei = result;
                },
                error: (err) => {
                    this.router.navigate(["../"], { relativeTo: this.route });
                }
            })
        );
    }

    save() {
        if (!this.imei) {
            return;
        }

        if (this.MODE === BaseComponentMode.ADD) {
            this.subscriptions.push(
                this.imeisService.addimei(this.imei).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("imeis.detail.add.success"));
                    this.router.navigate(["../"], { relativeTo: this.route });
                })
            );
        }

        if (this.MODE === BaseComponentMode.EDIT) {
            this.subscriptions.push(
                this.imeisService.updateimei(this.imei).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("imeis.detail.edit.success"));
                    this.router.navigate(["../"], { relativeTo: this.route });
                })
            );
        }
    }

    back() {
        this._location.back();
    }
}
