<div class="content__inner">
    <header class="content__title">
        <h1 *ngIf="MODE === 'EDIT'">{{ "cameras.detail.edit.title" | translate }}</h1>

        <div class="actions">
            <button (click)="back()" class="btn btn-secondary btn--icon-text"><i class="zmdi zmdi-arrow-back"></i> {{ "global.buttons.back" | translate }}</button>
        </div>
    </header>

    <div class="card">
        <div class="card-body">
            <form name="form" class="row" *ngIf="camera" (ngSubmit)="f.form.valid && save()" #f="ngForm" novalidate>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "cameras.props.imei" | translate }}</label>
                        <input name="imei" type="text" class="form-control" [(ngModel)]="camera.imei" readonly="true" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "cameras.props.battery" | translate }}</label>
                        <input name="battery" type="text" class="form-control" [(ngModel)]="camera.battery" disabled="true" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "cameras.props.firmware" | translate }}</label>
                        <input name="firmware" type="text" class="form-control" [(ngModel)]="camera.firmware" disabled="true" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "cameras.props.cameraType" | translate }}</label>
                        <input name="cameraType" type="text" class="form-control" [(ngModel)]="camera.productType" disabled="true" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "cameras.props.user" | translate }}</label>
                        <input name="user" type="text" class="form-control" [ngModel]="camera.getUser()" disabled="true" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "cameras.props.lastDailyReport" | translate }}</label>
                        <input name="lastDailyReport" type="text" class="form-control" [ngModel]="camera.lastDailyReport | date: UTCDATEFORMAT" disabled="true" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "cameras.props.batteryType" | translate }}</label>
                        <input name="batteryType" type="text" class="form-control" value="{{ 'cameras.props.batteryType_0' | translate }}" disabled="true" *ngIf="camera.batteryType !== '1'" />
                        <input name="batteryType" type="text" class="form-control" value="{{ 'cameras.props.batteryType_1' | translate }}" disabled="true" *ngIf="camera.batteryType === '1'" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "cameras.props.corruptPictures" | translate }}</label>
                        <input name="corruptPictures" type="text" class="form-control" [(ngModel)]="camera.corruptPictures" disabled="true" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "cameras.props.isHighRes" | translate }}</label>
                        <div class="row">
                            <div class="col-12">
                                <div class="toggle-switch">
                                    <input type="checkbox" class="toggle-switch__checkbox" [(ngModel)]="camera.isHighRes" id="isHighRes" name="isHighRes" [disabled]="true" />
                                    <i class="toggle-switch__helper"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "cameras.props.isSendingHighRes" | translate }}</label>
                        <div class="row">
                            <div class="col-12">
                                <div class="toggle-switch">
                                    <input type="checkbox" class="toggle-switch__checkbox" [(ngModel)]="camera.isSendingHighRes" id="isSendingHighRes" name="isSendingHighRes" [disabled]="true" />
                                    <i class="toggle-switch__helper"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "cameras.props.lastDailyReportContent" | translate }}</label>
                        <textarea name="lastDailyReportContent" class="form-control" readonly="true" style="height: 130px">{{ camera.lastDailyReportContent }}</textarea>
                        <i class="form-group__bar"></i>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="card">
        <div class="card-body">
            <app-camera-data-and-settings-4g-v1v2 *ngIf="camera" [camera]="camera" (saveClickedAndValid)="save()" (reconnectSendSuccessful)="initEditMode()"></app-camera-data-and-settings-4g-v1v2>
        </div>
    </div>

    <!-- corrupt pictures / statistic -->
    <div class="card">
        <div class="card-body">
            <h4 class="card-title">{{ "cameras.detail.corruptstats.title" | translate }}</h4>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <input
                            type="text"
                            id="start"
                            name="start"
                            [(ngModel)]="start"
                            placeholder="DD.MM.YYYY"
                            class="form-control"
                            bsDatepicker
                            [bsConfig]="{ adaptivePosition: true, isAnimated: true, containerClass: 'date-picker', dateInputFormat: 'DD.MM.YYYY' }"
                        />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input
                            type="text"
                            id="end"
                            name="end"
                            [(ngModel)]="end"
                            placeholder="DD.MM.YYYY"
                            class="form-control"
                            bsDatepicker
                            [bsConfig]="{ adaptivePosition: true, isAnimated: true, containerClass: 'date-picker', dateInputFormat: 'DD.MM.YYYY' }"
                        />
                    </div>
                </div>

                <div class="col-12">
                    <button class="float-right btn btn-success btn--icon-text" (click)="loadStatisticData()"><i class="zmdi zmdi-calendar"></i> {{ "global.buttons.filter" | translate }}</button>
                </div>
            </div>
            <div class="row">
                <x-chartist [configuration]="chartistConfig"> </x-chartist>
            </div>
        </div>
    </div>
</div>
