<form name="form" class="row" *ngIf="camera && camera.settings" (ngSubmit)="f.form.valid && save()" #f="ngForm" novalidate>
    <div class="col-md-12">
        <tabset>
            <tab [heading]="'cameras.detail.edit.tabs.basedata' | translate">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "cameras.props.name" | translate }}</label>
                            <input name="name" type="text" class="form-control" [(ngModel)]="camera.name" #name="ngModel" [disabled]="!mayEditBasedata" [ngClass]="{ 'is-invalid': f.submitted && name.invalid }" required />
                            <i class="form-group__bar"></i>
                            <div class="invalid-tooltip">
                                {{ "cameras.detail.validation.name" | translate }}
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "cameras.props.phoneRegion" | translate }}</label>
                            <ng-select
                                name="phoneRegion"
                                [items]="countries"
                                bindLabel="name"
                                bindValue="code"
                                placeholder=""
                                [(ngModel)]="camera.phoneRegion"
                                (ngModelChange)="phoneOrPhoneRegionChanged()"
                                #phoneRegion="ngModel"
                                [ngClass]="{ 'is-invalid': f.submitted && phoneRegion.invalid }"
                                required
                                [disabled]="!mayEditBasedata"
                            >
                            </ng-select>
                            <div class="invalid-tooltip">
                                {{ "cameras.detail.validation.phoneRegion" | translate }}
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "cameras.props.phone" | translate }}</label>
                            <input
                                name="phone"
                                type="text"
                                class="form-control"
                                [(ngModel)]="camera.phone"
                                (ngModelChange)="phoneOrPhoneRegionChanged()"
                                #phone="ngModel"
                                [ngClass]="{ 'is-invalid': f.submitted && phone.invalid }"
                                required
                                [disabled]="!mayEditBasedata"
                            />
                            <i class="form-group__bar"></i>
                            <div class="invalid-tooltip">
                                {{ "cameras.detail.validation.phone" | translate }}
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "cameras.props.phoneFormatted" | translate }}</label>
                            <input name="phoneFormatted" type="text" class="form-control" [(ngModel)]="camera.phoneFormatted" disabled="true" />
                            <i class="form-group__bar"></i>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "cameras.props.gpsActive" | translate }}</label>
                            <div class="row">
                                <div class="col-12">
                                    <div class="toggle-switch">
                                        <input type="checkbox" class="toggle-switch__checkbox" [(ngModel)]="camera.gpsActive" id="gpsActive" name="gpsActive" />
                                        <i class="toggle-switch__helper"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </tab>

            <tab [heading]="'cameras.detail.edit.tabs.settings' | translate">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "cameras.props.settings.cameraMode" | translate }}</label>
                            <ng-select name="cameraMode" [items]="cameraModes" placeholder="" bindLabel="value" bindValue="key" [clearable]="false" [(ngModel)]="camera.settings.cameraMode"> </ng-select>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "cameras.props.settings.pictureSize" | translate }}</label>
                            <ng-select name="pictureSize" [items]="pictureSizes" placeholder="" bindLabel="value" bindValue="key" [clearable]="false" [(ngModel)]="camera.settings.pictureSize"> </ng-select>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "cameras.props.settings.videoSize" | translate }}</label>
                            <ng-select name="videoSize" [items]="videoSizes" placeholder="" bindLabel="value" bindValue="key" [clearable]="false" [(ngModel)]="camera.settings.videoSize"> </ng-select>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "cameras.props.settings.videoLength" | translate }}</label>
                            <input
                                name="videoLength"
                                type="number"
                                class="form-control"
                                [(ngModel)]="camera.settings.videoLength"
                                #videoLength="ngModel"
                                [ngClass]="{ 'is-invalid': f.submitted && videoLength.invalid }"
                                pattern="^([5-9]|[1-4][0-9]|5[0-9])$"
                                required
                            />
                            <i class="form-group__bar"></i>
                            <div class="invalid-tooltip">
                                {{ "cameras.detail.validation.settings.videoLength" | translate }}
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "cameras.props.settings.multiShot" | translate }}</label>
                            <ng-select name="multiShot" [items]="multiShots" placeholder="" bindLabel="value" bindValue="key" [clearable]="false" [(ngModel)]="camera.settings.multiShot"> </ng-select>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "cameras.props.settings.nightMode" | translate }}</label>
                            <ng-select name="nightMode" [items]="nightModes" placeholder="" bindLabel="value" bindValue="key" [clearable]="false" [(ngModel)]="camera.settings.nightMode"> </ng-select>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "cameras.props.settings.flashLED" | translate }}</label>
                            <ng-select name="flashLED" [items]="flashLEDs" placeholder="" bindLabel="value" bindValue="key" [clearable]="false" [(ngModel)]="camera.settings.flashLED"> </ng-select>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "cameras.props.settings.sdSycle" | translate }}</label>
                            <ng-select name="sdSycle" [items]="sdSycles" placeholder="" bindLabel="value" bindValue="key" [clearable]="false" [(ngModel)]="camera.settings.sdSycle"> </ng-select>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "cameras.props.settings.pirSensitivity" | translate }}</label>
                            <ng-select name="pirSensitivity" [items]="pirSensitivities" placeholder="" bindLabel="value" bindValue="key" [clearable]="false" [(ngModel)]="camera.settings.pirSensitivity"> </ng-select>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "cameras.props.settings.pirSwitch" | translate }}</label>
                            <ng-select name="pirSwitch" [items]="pirSwitches" placeholder="" bindLabel="value" bindValue="key" [clearable]="false" [(ngModel)]="camera.settings.pirSwitch"> </ng-select>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "cameras.props.settings.delay" | translate }}</label>
                            <div class="row">
                                <div class="col-8">
                                    <timepicker
                                        id="delay"
                                        name="delay"
                                        [(ngModel)]="delay"
                                        [showMeridian]="false"
                                        [showSpinners]="false"
                                        [showSeconds]="true"
                                        [minuteStep]="1"
                                        [secondsStep]="1"
                                        [readonlyInput]="!this.camera.settings.delayActivated"
                                    ></timepicker>
                                    <i class="form-group__bar"></i>
                                </div>

                                <div class="col-4">
                                    <div class="toggle-switch">
                                        <input type="checkbox" class="toggle-switch__checkbox" [(ngModel)]="camera.settings.delayActivated" id="delayActivated" name="delayActivated" />
                                        <i class="toggle-switch__helper"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "cameras.props.settings.timeLapse" | translate }}</label>
                            <div class="row">
                                <div class="col-8">
                                    <input name="timeLapseHH" type="text" class="form-control timelapseInput" [(ngModel)]="timeLapseHH" placeholder="HH" [readonly]="!this.camera.settings.timeLapseActivated" />
                                    <span class="float-left timeDivider">:</span>
                                    <input name="timeLapseMM" type="text" class="form-control timelapseInput" [(ngModel)]="timeLapseMM" placeholder="MM" [readonly]="!this.camera.settings.timeLapseActivated" />
                                    <span class="float-left timeDivider">:</span>
                                    <input name="timeLapseSS" type="text" class="form-control timelapseInput" [(ngModel)]="timeLapseSS" placeholder="SS" [readonly]="!this.camera.settings.timeLapseActivated" />
                                </div>

                                <div class="col-4">
                                    <div class="toggle-switch">
                                        <input type="checkbox" class="toggle-switch__checkbox" [(ngModel)]="camera.settings.timeLapseActivated" id="timeLapseActivated" name="timeLapseActivated" />
                                        <i class="toggle-switch__helper"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "cameras.props.settings.workTimer1" | translate }}</label>
                            <div class="row">
                                <div class="col-8">
                                    <timepicker
                                        id="workTimer1"
                                        name="workTimer1"
                                        [(ngModel)]="workTimer1"
                                        [showMeridian]="false"
                                        [showSpinners]="false"
                                        [minuteStep]="1"
                                        [readonlyInput]="!this.camera.settings.workTimer1Activated"
                                    ></timepicker>
                                    <timepicker
                                        id="workTimer1end"
                                        name="workTimer1end"
                                        [(ngModel)]="workTimer1end"
                                        [showMeridian]="false"
                                        [showSpinners]="false"
                                        [minuteStep]="1"
                                        [readonlyInput]="!this.camera.settings.workTimer1Activated"
                                    ></timepicker>
                                    <i class="form-group__bar"></i>
                                </div>

                                <div class="col-4">
                                    <div class="toggle-switch">
                                        <input type="checkbox" class="toggle-switch__checkbox" [(ngModel)]="camera.settings.workTimer1Activated" id="workTimer1Activated" name="workTimer1Activated" />
                                        <i class="toggle-switch__helper"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "cameras.props.settings.workTimer2" | translate }}</label>
                            <div class="row">
                                <div class="col-8">
                                    <timepicker
                                        id="workTimer2"
                                        name="workTimer2"
                                        [(ngModel)]="workTimer2"
                                        [showMeridian]="false"
                                        [showSpinners]="false"
                                        [minuteStep]="1"
                                        [readonlyInput]="!this.camera.settings.workTimer2Activated"
                                    ></timepicker>
                                    <timepicker
                                        id="workTimer2end"
                                        name="workTimer2end"
                                        [(ngModel)]="workTimer2end"
                                        [showMeridian]="false"
                                        [showSpinners]="false"
                                        [minuteStep]="1"
                                        [readonlyInput]="!this.camera.settings.workTimer2Activated"
                                    ></timepicker>
                                    <i class="form-group__bar"></i>
                                </div>

                                <div class="col-4">
                                    <div class="toggle-switch">
                                        <input type="checkbox" class="toggle-switch__checkbox" [(ngModel)]="camera.settings.workTimer2Activated" id="workTimer2Activated" name="workTimer2Activated" />
                                        <i class="toggle-switch__helper"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "cameras.props.settings.multishotUpload" | translate }}</label>
                            <input
                                name="multishotUpload"
                                type="text"
                                class="form-control"
                                [(ngModel)]="camera.settings.multishotUpload"
                                #multishotUpload="ngModel"
                                [ngClass]="{ 'is-invalid': f.submitted && multishotUpload.invalid }"
                                [pattern]="cameraMultishotInputPattern()"
                                required
                            />
                            <i class="form-group__bar"></i>
                            <div class="invalid-tooltip" *ngIf="cameraMultishotHD()">
                                {{ "cameras.detail.validation.multishotUploadHD" | translate }}
                            </div>
                            <div class="invalid-tooltip" *ngIf="!cameraMultishotHD()">
                                {{ "cameras.detail.validation.multishotUpload" | translate }}
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "cameras.props.doNotSendHighRes" | translate }}</label>
                            <div class="row">
                                <div class="col-12">
                                    <div class="toggle-switch">
                                        <input type="checkbox" class="toggle-switch__checkbox" [checked]="!camera.doNotSendHighRes" (change)="swapDoNotSendHighRes()" id="doSendHighRes" name="doSendHighRes" />
                                        <i class="toggle-switch__helper"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "cameras.props.settings.confirmedByCam" | translate }}</label>
                            <div class="row">
                                <div class="col-12">
                                    <div class="toggle-switch">
                                        <input type="checkbox" class="toggle-switch__checkbox" [(ngModel)]="camera.settings.confirmedByCam" id="confirmedByCam" name="confirmedByCam" [disabled]="true" />
                                        <i class="toggle-switch__helper"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </tab>
        </tabset>
    </div>

    <div class="col-md-12">
        <button type="button" class="float-left btn btn-danger btn--icon-text" (click)="reconnectCamera()" *ngIf="showReconnectButton"><i class="zmdi zmdi-refresh"></i> {{ "cameras.detail.edit.reconnect.button" | translate }}</button>

        <button *ngIf="showAddStolenCameraButton()" (click)="addStolenCamera()" class="btn btn-warning btn--icon-text" style="margin-left: 25px"><i class="zmdi zmdi-collection-plus"></i> {{ "stolencameras.list.add" | translate }}</button>
        <button *ngIf="showEditStolenCameraButton()" (click)="editStolenCamera()" class="btn btn-warning btn--icon-text" style="margin-left: 25px"><i class="zmdi zmdi-edit"></i> {{ "stolencameras.list.edit" | translate }}</button>

        <button class="float-right btn btn-success btn--icon-text" [disabled]="f.submitted && f.invalid"><i class="zmdi zmdi-check"></i> {{ "global.buttons.save" | translate }}</button>
    </div>
</form>
