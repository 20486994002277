import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorageAdapter } from "@impacgroup/angular-oauth-base";
import { ApiListQueryParameter, BaseDatatableStateSaveMode } from "@impacgroup/angular-baselib";
import { map } from "rxjs/operators";
import { plainToInstance } from "class-transformer";
import { AboUser } from "../../api-models/AboUser";

export interface IAboUserConfigService {
    aboUsersAPI: string;
    utcDateFormat: string;
    datatableStateSaveMode: BaseDatatableStateSaveMode;
}

export const AboUserConfigService = new InjectionToken<IAboUserConfigService>("AboUserConfig");

@Injectable()
export class AboUsersService {
    public UTCDATEFORMAT: string = "";
    public datatableStateSaveMode: BaseDatatableStateSaveMode;

    constructor(@Inject(AboUserConfigService) private aboUserConfig: IAboUserConfigService, private http: HttpClient, private oauthStorageAdapter: OAuthStorageAdapter) {
        this.UTCDATEFORMAT = this.aboUserConfig.utcDateFormat;
        this.datatableStateSaveMode = this.aboUserConfig.datatableStateSaveMode;
    }

    public aboUsers(params: ApiListQueryParameter): Observable<{ list: AboUser[]; count: number; total: number }> {
        return this.http
            .get<any>(this.getAboUsersServiceUrl(""), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                params: params as any,
                observe: "response"
            })
            .pipe(
                map((result) => {
                    let count = result.body.length;
                    let total = result.body.length;
                    try {
                        count = parseInt(result.headers.get("X-Pagination-Count") ?? "0", 10);
                    } catch (e) {}
                    try {
                        total = parseInt(result.headers.get("X-Total-Count") ?? "0", 10);
                    } catch (e) {}

                    return {
                        list: plainToInstance(AboUser, result.body as any[]),
                        count: count,
                        total: total
                    };
                })
            );
    }

    public aboUserDetail(id: string): Observable<AboUser> {
        return this.http
            .get<Object>(this.getAboUsersServiceUrl(id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToInstance(AboUser, result)));
    }

    private getAboUsersServiceUrl(postfix: string): string {
        return this.aboUserConfig.aboUsersAPI + postfix;
    }
}
