import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER, Injector } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateModule, TranslateLoader, TranslateService } from "@ngx-translate/core";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { AppService } from "./app.service";
import { AppComponent } from "./app.component";
import { SidebarComponent } from "./layout/sidebar/sidebar.component";
import { HeaderComponent } from "./layout/header/header.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { LogoComponent } from "./layout/header/logo/logo.component";
import { NavigationTriggerComponent } from "./layout/header/navigation-trigger/navigation-trigger.component";
import { UserComponent } from "./layout/sidebar/user/user.component";
import { PageLoaderComponent } from "./layout/page-loader/page-loader.component";

import { FullLayoutComponent } from "./layout/layouts/layout-full/layout.component";
import { SingleLayoutComponent } from "./layout/layouts/layout-single/layout.component";

import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { TabsModule } from "ngx-bootstrap/tabs";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { NgScrollbarModule } from "ngx-scrollbar";
import { AppRoutingModule } from "./app-routing.module";
import { translateLoaderService } from "./global/translate/translateloader.service";
import { NgSelectModule } from "@ng-select/ng-select";
import { OAuthModule } from "@impacgroup/angular-oauth-base";
import { PermissionsConfig } from "./config/PermissionsConfig";
import { environment } from "../environments/environment";
import { AngularBaseModule, BaseDatatableStateSaveMode } from "@impacgroup/angular-baselib";
import { HttpErrorInterceptor } from "./global/api-handling/HttpErrorInterceptor";
import { FormsModule } from "@angular/forms";
import { AlertModule } from "ngx-bootstrap/alert";
import { OrganizationsModule, RolesModule, UsersModule } from "@impacgroup/angular-access-management";
import { ProfileModule, ResetPasswordModule } from "@impacgroup/angular-selfservice";
import { LOCATION_INITIALIZED } from "@angular/common";
import { HttpLoadingService } from "./global/api-handling/HttpLoadingService";
import { LanguagesModule, LanguagesService } from "@impacgroup/angular-languages";
import { MailTemplatesModule, SmtpMailAccountsModule, MailTemplateLanguagesConfigService } from "@impacgroup/angular-mailtemplates";
import { RegistrationConfigModule } from "@impacgroup/angular-registration";
import {
    FirebaseConfigModule,
    FirebaseDevicesModule,
    FirebaseNotificationsModule,
    FirebaseNotificationTemplatesModule,
    FirebaseNotificationLanguagesConfigService,
    FirebaseNotificationTemplateLanguagesConfigService
} from "@impacgroup/angular-firebase";
import {
    PaypalBillingPlansModule,
    PaypalConfigModule,
    PaypalPaymentsModule,
    PaypalBillingAgreementsModule,
    KlarnaConfigModule,
    KlarnaAboPlansModule,
    KlarnaAboAgreementsModule,
    AppleConfigModule,
    GoogleConfigModule
} from "@impacgroup/angular-payment";
import { WebSmsConfigModule, WebSmsNotificationsModule } from "@impacgroup/angular-sms";
import { QuillModule } from "ngx-quill";
import { ImeisModule } from "./modules/imeis/imeis.module";
import { CamerasModule } from "./modules/cameras/cameras.module";
import { SystemLogMessagesModule, SystemJobPerformancesModule, SystemErrorsModule } from "@impacgroup/angular-systemhealth";
import { AboUsersModule } from "./modules/abos/abousers.module";
import { FirmwaresModule } from "./modules/firmwares/firmwares.module";
import { CrashlogApplicationsModule, CrashlogMessagesModule, CrashlogErrorsModule } from "@impacgroup/angular-crashlogs";
import { TextPoolsModule, TextPoolLanguagesConfigService } from "@impacgroup/angular-texts";
import { StolenCamerasModule } from "./modules/stolencameras/stolencameras.module";
import { StandardCountryService } from "./global/services/country.service";
import { ChartistModule } from "ng-chartist";

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
    return () =>
        new Promise<any>((resolve: any) => {
            const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
            locationInitialized.then(() => {
                translate.addLangs(["en"]);

                const langToSet = "en";
                translate.setDefaultLang("en");
                translate.use(langToSet).subscribe(
                    () => {
                        // console.log(`Successfully initialized '${langToSet}' language.'`);
                    },
                    (err) => {
                        console.error(`Problem with '${langToSet}' language initialization.'`);
                    },
                    () => {
                        resolve(null);
                    }
                );
            });
        });
}

export const httpInterceptorProviders = [{ provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }];
@NgModule({
    declarations: [AppComponent, SidebarComponent, HeaderComponent, FooterComponent, FullLayoutComponent, SingleLayoutComponent, LogoComponent, NavigationTriggerComponent, UserComponent, PageLoaderComponent],
    imports: [
        AngularBaseModule.forRoot(environment.httpRequestTimeoutSeconds),
        OAuthModule.forRoot(
            {
                PERMISSIONS: PermissionsConfig.PERMISSIONS
            },
            environment.oauthBaseAPI
        ),
        OrganizationsModule.forRoot({
            organizationsAPI: environment.organizationsAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        RolesModule.forRoot({
            rolesAPI: environment.rolesAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        UsersModule.forRoot({
            usersAPI: environment.usersAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        ProfileModule.forRoot({
            profileAPI: environment.profileAPI
        }),
        ResetPasswordModule.forRoot({
            resetPasswordAPI: environment.resetPasswordAPI
        }),
        LanguagesModule.forRoot({
            languagesAPI: environment.languagesAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        SmtpMailAccountsModule.forRoot({
            smtpMailAccountsAPI: environment.smtpMailAccountsAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        MailTemplatesModule.forRoot({
            mailtemplatesAPI: environment.mailtemplatesAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        RegistrationConfigModule.forRoot({
            registrationConfigAPI: environment.registrationConfigAPI
        }),
        FirebaseConfigModule.forRoot({
            firebaseConfigAPI: environment.firebaseConfigAPI
        }),
        FirebaseDevicesModule.forRoot({
            firebaseDevicesAPI: environment.firebaseDevicesAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        FirebaseNotificationsModule.forRoot({
            firebaseNotificationsAPI: environment.firebaseNotificationsAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        FirebaseNotificationTemplatesModule.forRoot({
            firebaseNotificationTemplatesAPI: environment.firebaseNotificationTemplatesAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        PaypalConfigModule.forRoot({
            paypalConfigAPI: environment.paypalConfigAPI
        }),
        PaypalBillingPlansModule.forRoot({
            paypalBillingPlansAPI: environment.paypalBillingPlansAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        PaypalBillingAgreementsModule.forRoot({
            paypalBillingAgreementsAPI: environment.paypalBillingAgreementsAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        KlarnaConfigModule.forRoot({
            klarnaConfigAPI: environment.klarnaConfigAPI
        }),
        KlarnaAboPlansModule.forRoot({
            klarnaAboPlansAPI: environment.klarnaAboPlansAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        KlarnaAboAgreementsModule.forRoot({
            klarnaAboAgreementsAPI: environment.klarnaAboAgreementsAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        WebSmsConfigModule.forRoot({
            webSmsConfigAPI: environment.webSmsConfigAPI
        }),
        WebSmsNotificationsModule.forRoot({
            webSmsNotificationsAPI: environment.webSmsNotificationsAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE,
            enablePhonenumberValidation: false
        }),
        SystemLogMessagesModule.forRoot({
            systemLogMessagesAPI: environment.systemLogMessagesAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        SystemJobPerformancesModule.forRoot({
            systemJobPerformancesAPI: environment.systemJobPerformancesAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        SystemErrorsModule.forRoot({
            systemErrorsAPI: environment.systemErrorsAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        ImeisModule.forRoot({
            imeisAPI: environment.imeisAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        CamerasModule.forRoot({
            camerasAPI: environment.camerasAPI,
            cameras4GProAPI: environment.cameras4GProAPI,
            cameras4GProWaAPI: environment.cameras4GProWaAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        AboUsersModule.forRoot({
            aboUsersAPI: environment.aboUsersAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        FirmwaresModule.forRoot({
            firmwaresAPI: environment.firmwaresAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        AppleConfigModule.forRoot({
            appleConfigAPI: environment.appleConfigAPI
        }),
        GoogleConfigModule.forRoot({
            googleConfigAPI: environment.googleConfigAPI
        }),
        CrashlogApplicationsModule.forRoot({
            crashlogApplicationsAPI: environment.crashlogApplicationsAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        CrashlogMessagesModule.forRoot({
            crashlogMessagesAPI: environment.crashlogMessagesAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        CrashlogErrorsModule.forRoot({
            crashlogErrorsAPI: environment.crashlogErrorsAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        TextPoolsModule.forRoot({
            textPoolsAPI: environment.textPoolsAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        StolenCamerasModule.forRoot({
            stolenCamerasAPI: environment.stolenCamerasAPI,
            utcDateFormat: environment.utcDateFormat,
            datatableStateSaveMode: BaseDatatableStateSaveMode.MODULE
        }),
        AlertModule.forRoot(),
        BrowserModule,
        BrowserAnimationsModule,
        ChartistModule,
        FormsModule,
        AppRoutingModule,
        NgScrollbarModule,
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        ButtonsModule.forRoot(),
        TabsModule.forRoot(),
        TimepickerModule.forRoot(),
        TranslateModule.forRoot(),
        NgSelectModule,
        HttpClientModule,
        QuillModule.forRoot(),
        BsDatepickerModule.forRoot()
    ],
    providers: [
        AppService,
        StandardCountryService,
        {
            provide: TranslateLoader,
            useFactory: translateLoaderService,
            deps: [HttpClient]
        },
        httpInterceptorProviders,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true
        },
        {
            provide: FirebaseNotificationLanguagesConfigService,
            useClass: LanguagesService
        },
        {
            provide: FirebaseNotificationTemplateLanguagesConfigService,
            useClass: LanguagesService
        },
        {
            provide: MailTemplateLanguagesConfigService,
            useClass: LanguagesService
        },
        {
            provide: TextPoolLanguagesConfigService,
            useClass: LanguagesService
        },
        HttpLoadingService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
