<div class="content__inner">
    <header class="content__title">
        <h1>{{ "cameras.detail.add.title" | translate }}</h1>

        <div class="actions">
            <button (click)="back()" class="btn btn-secondary btn--icon-text"><i class="zmdi zmdi-arrow-back"></i> {{ "global.buttons.back" | translate }}</button>
        </div>
    </header>

    <form name="form" #f="ngForm" novalidate>
        <div class="card">
            <div class="card-body row">
                <!-- User - Search -->
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "cameras.detail.add.user.choice.title" | translate }}</label>
                        <div class="input-group">
                            <input
                                name="userQuery"
                                type="search"
                                class="form-control p-0"
                                [(ngModel)]="userQuery"
                                placeholder="{{ 'cameras.detail.add.user.choice.placeholder' | translate }}"
                                autocomplete="off"
                                [typeahead]="userSuggestions"
                                [typeaheadAsync]="true"
                                [typeaheadWaitMs]="debounceTime"
                                [typeaheadItemTemplate]="customItemTemplate"
                                (typeaheadOnBlur)="userSuggestionOnBlur()"
                                (typeaheadOnSelect)="userSuggestionOnSelect($event)"
                                (typeaheadNoResults)="userSuggestionOnNoResults()"
                                [isAnimated]="true"
                            />
                            <div class="input-group-append">
                                <button *ngIf="userQuery || user" tabindex="-1" (click)="clearUserQuery()" class="input-group-text search-clear" aria-hidden="true">×</button>
                                <span *ngIf="userSuggLoadingStatus === LoadingStatus.loading" class="input-group-text"><i class="zmdi zmdi-refresh-sync animated infinite rotate loading"></i></span>
                                <button *ngIf="userSuggLoadingStatus === LoadingStatus.error" tabindex="-1" (click)="userQueryChanged()" class="input-group-text bg-danger text-white"><i class="zmdi zmdi-refresh"></i></button>
                            </div>
                            <i class="form-group__bar"></i>
                        </div>
                        <ng-template #customItemTemplate let-model="item" let-index="index" let-match="match" let-query="query">
                            <span [innerHtml]="highlight(match, query)"></span>
                        </ng-template>
                    </div>
                </div>

                <div class="col-md-6" *ngIf="user">
                    <div class="form-group">
                        <label>{{ "cameras.detail.add.user.email" | translate }}</label>
                        <input name="email" type="text" class="form-control" [value]="user.email" readonly="true" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="card" *ngIf="user">
            <div class="card-body row">
                <!-- Imei - Search -->
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "cameras.detail.add.imei.choice.title" | translate }}</label>
                        <div class="input-group">
                            <input
                                name="imeiQuery"
                                type="search"
                                autocomplete="off"
                                class="form-control px-0"
                                [ngModel]="imeiQuery"
                                (ngModelChange)="imeiQuerySubject.next($event)"
                                maxlength="15"
                                [class]="{ 'is-valid': imeiValid, 'is-invalid': imeiInvalid }"
                                placeholder="{{ 'cameras.detail.add.imei.choice.placeholder' | translate }}"
                            />
                            <div class="input-group-append" *ngIf="imeiQuery && imeiQuery.length === 15">
                                <button tabindex="-1" (click)="clearImeiQuery()" class="input-group-text search-clear" aria-hidden="true">×</button>

                                <span class="input-group-text" *ngIf="imeiInfoLoadingStatus === LoadingStatus.loading"><i class="zmdi zmdi-refresh-sync animated infinite rotate loading"></i></span>
                                <button tabindex="-1" (click)="imeiQueryChanged()" class="input-group-text bg-danger text-white" *ngIf="imeiInfoLoadingStatus === LoadingStatus.error"><i class="zmdi zmdi-refresh"></i></button>

                                <span class="input-group-text" *ngIf="imeiStatus === 'imeiInvalid'"><i class="zmdi zmdi-close mr-3"></i></span>
                                <span class="input-group-text bg-success text-white" *ngIf="imeiValid"><i class="zmdi zmdi-check"></i></span>
                                <span class="input-group-text bg-danger text-white" *ngIf="imeiInvalid"><i class="zmdi zmdi-block"></i></span>
                            </div>
                            <div [class]="{ 'valid-tooltip': imeiValid, 'invalid-tooltip': imeiInvalid }" *ngIf="imeiStatus">
                                {{ "cameras.detail.add.imei.choice.tooltips." + imeiStatus | translate }}
                            </div>
                        </div>

                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6" *ngIf="imeiStatus && imeiInvalid">
                    <ng-container *appHasPermission="'IMEIS.ADD'">
                        <div *ngIf="imeiStatus === 'imeiDoesNotExist'">
                            <div class="form-group">
                                <label>{{ "imeis.props.productType" | translate }}</label>
                                <ng-select
                                    name="productType"
                                    [items]="productTypes"
                                    placeholder=""
                                    bindLabel="name"
                                    bindValue="key"
                                    [clearable]="false"
                                    [(ngModel)]="newImeiProductType"
                                    #productType="ngModel"
                                    [ngClass]="{ 'is-invalid': f.submitted && productType.invalid }"
                                    required
                                >
                                </ng-select>
                                <i class="form-group__bar"></i>
                                <div class="invalid-tooltip">
                                    {{ "cameras.detail.add.imei.choice.productType" | translate }}
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <label>
                        <i class="zmdi zmdi-info text-success mr-2" *ngIf="imeiValid"></i>
                        <i class="zmdi zmdi-alert-circle text-danger mr-2" *ngIf="imeiInvalid"></i>
                        <span>{{ "cameras.detail.add.imei.choice.informations." + imeiStatus + ".title" | translate }}</span>
                    </label>
                    <div>{{ "cameras.detail.add.imei.choice.informations." + imeiStatus + ".description" | translate }}</div>
                </div>

                <ng-container *ngIf="imeiStatus === 'imeiDoesNotExist'">
                    <div class="col-md-12" *appHasPermission="'IMEIS.ADD'">
                        <button (click)="addImei()" class="btn btn-success btn--icon-text float-right">
                            <i class="zmdi zmdi-collection-plus"></i>
                            <span>{{ "cameras.detail.add.imei.choice.buttons.imeiDoesNotExist" | translate }}</span>
                        </button>
                    </div>
                </ng-container>

                <div class="col-md-12" *ngIf="imeiStatus === 'imeiExists'">
                    <div class="form-group">
                        <ng-container *ngIf="productFilteredCameras && productFilteredCameras.length > 0; else noUserCamerasTemplate">
                            <label>{{ "cameras.detail.add.camera.choice.title" | translate }}</label>
                            <div class="input-group">
                                <div class="radio radio--inline">
                                    <input type="radio" name="cameraNewOrRepl" id="cameraNew" [(ngModel)]="cameraNewOrRepl" (ngModelChange)="newCameraChoosen($event)" [value]="'new'" />
                                    <label class="radio__label" for="cameraNew">{{ "cameras.detail.add.camera.choice.new" | translate }}</label>
                                </div>
                                <div class="radio radio--inline mt-0">
                                    <input type="radio" name="cameraNewOrRepl" id="cameraRepl" [(ngModel)]="cameraNewOrRepl" (ngModelChange)="replCameraChoosen($event)" [value]="'repl'" />
                                    <label class="radio__label" for="cameraRepl">{{ "cameras.detail.add.camera.choice.replacement" | translate }}</label>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #noUserCamerasTemplate>
                            <div>
                                {{ "cameras.detail.add.camera.choice.noUsersCamerasAvailable.title" | translate : { productType: ("cameras.detail.add.camera.choice.noUsersCamerasAvailable.type." + imeiProductType | translate) } }}
                            </div>
                            <div>{{ "cameras.detail.add.camera.choice.noUsersCamerasAvailable.description" | translate }}</div>
                        </ng-template>
                    </div>
                </div>

                <!-- Replacement camera selection (if present) -->
                <div class="col-md-12" *ngIf="user && imeiStatus === 'imeiExists' && productFilteredCameras && productFilteredCameras.length > 0 && cameraNewOrRepl === 'repl'">
                    <div class="form-group">
                        <label>{{ "cameras.detail.add.camera.select.title" | translate }}</label>
                        <ng-select
                            [items]="productFilteredCameras"
                            [searchable]="false"
                            [placeholder]="'cameras.detail.add.camera.select.placeholder' | translate"
                            [(ngModel)]="selectedUsersCamera"
                            [ngModelOptions]="{ standalone: true }"
                            (ngModelChange)="cameraToReplaceSelected($event)"
                            [class.is-invalid]="selectedUsersCamera && (selectedUsersCamera.user !== user._id || !isAllowedProductTypeToReplace(imeiProductType, selectedUsersCamera.productType))"
                        >
                            <ng-template ng-label-tmp let-camera="item">
                                <span [class.text-danger]="camera.user !== user._id || !isAllowedProductTypeToReplace(imeiProductType, camera.productType)">{{
                                    camera.name + " (IMEI: " + camera.imei + (camera.phone ? " Phone: " + camera.phone : "") + ")"
                                }}</span>
                            </ng-template>

                            <ng-template ng-option-tmp let-camera="item">
                                <span>{{ camera.name + " (IMEI: " + camera.imei + (camera.phone ? " Phone: " + camera.phone : "") + ")" }}</span>
                            </ng-template>
                        </ng-select>
                        <div class="invalid-tooltip" *ngIf="selectedUsersCamera && (selectedUsersCamera.user !== user._id || !isAllowedProductTypeToReplace(imeiProductType, selectedUsersCamera.productType))">
                            <div *ngIf="selectedUsersCamera.user !== user._id">{{ "cameras.detail.add.camera.select.informations.cameraBelogsToOther" | translate }}</div>
                            <div *ngIf="!isAllowedProductTypeToReplace(imeiProductType, selectedUsersCamera.productType)">{{ "cameras.detail.add.camera.select.informations.productTypeVaries" | translate }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div class="card" *ngIf="user && imeiStatus === 'imeiExists' && (cameraNewOrRepl || productFilteredCameras?.length === 0) && (camera4GV1V2 || camera4GPro)">
        <div class="card-body">
            <app-camera-data-and-settings-4g-v1v2 *ngIf="camera4GV1V2" [camera]="camera4GV1V2" (saveClickedAndValid)="save()" [showReconnectButton]="false" [showStolenButtons]="false"></app-camera-data-and-settings-4g-v1v2>
            <app-camera-data-and-settings-4g-pro *ngIf="camera4GPro" [camera]="camera4GPro" (saveClickedAndValid)="save()" [showReconnectButton]="false" [showStolenButtons]="false"></app-camera-data-and-settings-4g-pro>
        </div>
    </div>
</div>
