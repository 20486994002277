import { Component, OnInit, Inject, LOCALE_ID } from "@angular/core";
import { Location, formatDate } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, BaseComponent, BaseComponentMode } from "@impacgroup/angular-baselib";
// import ctTooltip from "@impacgroup/chartist-plugin-tooltip";
import { CamerasService } from "./cameras.service";
import { TranslateService } from "@ngx-translate/core";
import { Camera } from "../../api-models/Camera";
import * as moment from "moment";
import { ICameraCorruptPicture } from "../../api-models/ICameraCorruptPicture";
import { Configuration } from "ng-chartist";

@Component({
    selector: "app-cameradetail-4g-v1v2",
    templateUrl: "./cameradetail.component.html",
    styleUrls: ["cameradetail.component.scss"]
})
export class CameradetailComponent extends BaseComponent implements OnInit {
    private cameraId?: string;
    private productType?: string;
    public camera?: Camera;

    public UTCDATEFORMAT: string = "";

    // corrupt pictures statistics
    public start: Date = new Date();
    public end: Date = new Date();
    public corruptPictures: ICameraCorruptPicture[] = [];
    public chartistConfig: Configuration = {
        type: "Line",
        data: {
            labels: [],
            series: [
                {
                    name: "",
                    data: this.corruptPictures
                }
            ]
        },
        options: {
            height: "250px",
            fullWidth: true,
            low: 0,
            showArea: true,
            showPoint: true
            // plugins: [
            //     ctTooltip({
            //         valueTransform: function (value) {
            //             return value;
            //         }
            //     })
            // ]
        }
    };

    constructor(
        private route: ActivatedRoute,
        private camerasService: CamerasService,
        private router: Router,
        private alertService: AlertService,
        private translateService: TranslateService,
        private _location: Location,
        @Inject(LOCALE_ID) private locale: string
    ) {
        super();

        this.subscriptions.push(
            this.route.params.subscribe((params) => {
                this.cameraId = params["id"];
                this.productType = route.snapshot.data["productType"];

                if (this.productType && this.cameraId) {
                    this.initEditMode();
                } else {
                    this.back();
                }
            })
        );

        this.UTCDATEFORMAT = this.camerasService.UTCDATEFORMAT;

        this.start = moment(this.start).subtract(14, "days").toDate();
        this.loadStatisticData();
    }

    ngOnInit(): void {}

    initEditMode() {
        this.MODE = BaseComponentMode.EDIT;

        if (!this.productType || !this.cameraId) {
            return;
        }

        this.subscriptions.push(
            this.camerasService.cameradetail({ type: this.productType, id: this.cameraId }).subscribe({
                next: (result) => {
                    this.camera = result;
                },
                error: (err) => {
                    this.router.navigate(["../../"], { relativeTo: this.route });
                }
            })
        );
    }

    save() {
        if (!this.camera) {
            return;
        }

        this.subscriptions.push(
            this.camerasService.updatecamera(this.camera).subscribe((result) => {
                this.alertService.addSuccess(this.translateService.instant("cameras.detail.edit.success"));
                this.router.navigate(["../../"], { relativeTo: this.route });
            })
        );
    }

    back() {
        this._location.back();
    }

    loadStatisticData() {
        const start = moment(moment(this.start).format("YYYY-MM-DD") + " 00:00:00").toDate();
        const end = moment(moment(this.end).format("YYYY-MM-DD") + " 23:59:59").toDate();

        if (!this.productType || !this.cameraId) {
            return;
        }

        this.subscriptions.push(
            this.camerasService.corruptPicturesStatistics({ start, end, type: this.productType, id: this.cameraId }).subscribe((result) => {
                this.corruptPictures = result.map((tuple) => {
                    (tuple as any).meta = formatDate(tuple.date, "yyyy-MM-dd", this.locale);
                    return tuple;
                });
            })
        );
    }
}
