<div class="content__inner">
    <header class="content__title">
        <h1 *ngIf="MODE === 'VIEW'">{{ "abousers.detail.view.title" | translate }}</h1>

        <div class="actions">
            <button (click)="back()" class="btn btn-secondary btn--icon-text"><i class="zmdi zmdi-arrow-back"></i> {{ "global.buttons.back" | translate }}</button>
        </div>
    </header>

    <div class="card">
        <div class="card-body" *ngIf="aboUser && aboUser.userAboInfo">
            <h3 class="card-body__title">{{ "abousers.props.userinfo" | translate }}</h3>
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{ "abousers.props.firstname" | translate }}</label>
                        <input name="firstname" type="text" class="form-control" [ngModel]="aboUser.firstname" readonly="readonly" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{ "abousers.props.lastname" | translate }}</label>
                        <input name="lastname" type="text" class="form-control" [ngModel]="aboUser.lastname" readonly="readonly" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{ "abousers.props.email" | translate }}</label>
                        <input name="email" type="text" class="form-control" [ngModel]="aboUser.email" readonly="readonly" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{ "abousers.props.availableQuota" | translate }}</label>
                        <input name="availableQuota" type="text" class="form-control" [ngModel]="aboUser.userAboInfo.availableQuota" readonly="readonly" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{ "abousers.props.usedQuota" | translate }}</label>
                        <input name="usedQuota" type="text" class="form-control" [ngModel]="aboUser.userAboInfo.usedQuota" readonly="readonly" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{ "abousers.props.camMaySendHighRes" | translate }}</label>
                        <div class="row">
                            <div class="col-12">
                                <div class="toggle-switch">
                                    <input type="checkbox" class="toggle-switch__checkbox" [ngModel]="aboUser.userAboInfo.camMaySendHighRes" id="camMaySendHighRes" name="camMaySendHighRes" [disabled]="true" />
                                    <i class="toggle-switch__helper"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{ "abousers.props.availableVideoMBTransfer" | translate }}</label>
                        <input name="availableVideoMBTransfer" type="text" class="form-control" [ngModel]="aboUser.userAboInfo.availableVideoMBTransfer" readonly="readonly" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{ "abousers.props.usedVideoMBTransfer" | translate }}</label>
                        <input name="usedVideoMBTransfer" type="text" class="form-control" [ngModel]="aboUser.userAboInfo.usedVideoMBTransfer" readonly="readonly" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-body" *ngIf="aboUser && aboUser.userAboInfo">
            <h3 class="card-body__title">{{ "abousers.props.highres" | translate }}</h3>
            <div class="row" *ngIf="!aboUser.userAboInfo.highres">
                <div class="col-md-12">-</div>
            </div>

            <div class="row" *ngIf="aboUser.userAboInfo.highres">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{ "abousers.props.provider" | translate }}</label>
                        <input name="provider" type="text" class="form-control" [ngModel]="aboUser.userAboInfo.highres.provider" readonly="readonly" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{ "abousers.props.lastBillingDate" | translate }}</label>
                        <input name="lastBillingDate" type="text" class="form-control" [ngModel]="aboUser.userAboInfo.highres.lastBillingDate | date : UTCDATEFORMAT" readonly="readonly" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{ "abousers.props.nextBillingDate" | translate }}</label>
                        <input name="nextBillingDate" type="text" class="form-control" [ngModel]="aboUser.userAboInfo.highres.nextBillingDate | date : UTCDATEFORMAT" readonly="readonly" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{ "abousers.props.active" | translate }}</label>
                        <div class="row">
                            <div class="col-12">
                                <div class="toggle-switch">
                                    <input type="checkbox" class="toggle-switch__checkbox" [ngModel]="aboUser.userAboInfo.highres.active" id="isHighRes" name="isHighRes" [disabled]="true" />
                                    <i class="toggle-switch__helper"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4" *ngIf="!aboUser.userAboInfo.highres.active">
                    <div class="form-group">
                        <label>{{ "abousers.props.canceled" | translate }}</label>
                        <input name="canceled" type="text" class="form-control" [ngModel]="aboUser.userAboInfo.highres.canceled | date : UTCDATEFORMAT" readonly="readonly" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-4" *ngIf="!aboUser.userAboInfo.highres.active">
                    <div class="form-group">
                        <label>{{ "abousers.props.cancelReason" | translate }}</label>
                        <textarea name="cancelReason" type="text" class="form-control" [ngModel]="aboUser.userAboInfo.highres.cancelReason" readonly="readonly"></textarea>
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-4" *ngIf="!aboUser.userAboInfo.highres.active">
                    <div class="form-group">
                        <label>{{ "abousers.props.highResDeleteDate" | translate }}</label>
                        <input name="highResDeleteDate" type="text" class="form-control" [ngModel]="highResDeleteDate | date : UTCDATEFORMAT" readonly="readonly" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-4" *ngIf="!aboUser.userAboInfo.highres.active">
                    <div class="form-group">
                        <label>{{ "abousers.props.highResCamResetDate" | translate }}</label>
                        <input name="highResCamResetDate" type="text" class="form-control" [ngModel]="highResCamResetDate | date : UTCDATEFORMAT" readonly="readonly" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-12" *ngIf="aboUser.userAboInfo.highres.provider === 'google' && aboUser.userAboInfo.highres.lastRealtimeStatus">
                    <div class="form-group">
                        <label>{{ "abousers.props.lastRealtimeStatus" | translate }}</label>
                        <input name="lastRealtimeStatus" type="text" class="form-control" [ngModel]="'abousers.googleRTstatus.' + aboUser.userAboInfo.highres.lastRealtimeStatus | translate" readonly="readonly" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- quota subscriptions -->
    <div *ngIf="aboUser">
        <div class="card" *ngFor="let quotaAbo of aboUser.userAboInfo?.quota ?? []; let idx = index">
            <div class="card-body">
                <h3 class="card-body__title">
                    {{ "abousers.props.quota" | translate }} {{ idx + 1 }}<span *ngIf="quotaAbo.productId"> ({{ quotaAbo.productId }})</span>:
                </h3>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ "abousers.props.provider" | translate }}</label>
                            <input name="provider_{{ idx }}" type="text" class="form-control" [ngModel]="quotaAbo.provider" readonly="readonly" />
                            <i class="form-group__bar"></i>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ "abousers.props.lastBillingDate" | translate }}</label>
                            <input name="lastBillingDate_{{ idx }}" type="text" class="form-control" [ngModel]="quotaAbo.lastBillingDate | date : UTCDATEFORMAT" readonly="readonly" />
                            <i class="form-group__bar"></i>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ "abousers.props.nextBillingDate" | translate }}</label>
                            <input name="nextBillingDate_{{ idx }}" type="text" class="form-control" [ngModel]="quotaAbo.nextBillingDate | date : UTCDATEFORMAT" readonly="readonly" />
                            <i class="form-group__bar"></i>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ "abousers.props.active" | translate }}</label>
                            <div class="row">
                                <div class="col-12">
                                    <div class="toggle-switch">
                                        <input type="checkbox" class="toggle-switch__checkbox" [ngModel]="quotaAbo.active" id="isHighRes_{{ idx }}" name="isHighRes_{{ idx }}" [disabled]="true" />
                                        <i class="toggle-switch__helper"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4" *ngIf="!quotaAbo.active">
                        <div class="form-group">
                            <label>{{ "abousers.props.canceled" | translate }}</label>
                            <input name="canceled_{{ idx }}" type="text" class="form-control" [ngModel]="quotaAbo.canceled | date : UTCDATEFORMAT" readonly="readonly" />
                            <i class="form-group__bar"></i>
                        </div>
                    </div>

                    <div class="col-md-4" *ngIf="!quotaAbo.active">
                        <div class="form-group">
                            <label>{{ "abousers.props.cancelReason" | translate }}</label>
                            <textarea name="cancelReason_{{ idx }}" type="text" class="form-control" [ngModel]="quotaAbo.cancelReason" readonly="readonly"></textarea>
                            <i class="form-group__bar"></i>
                        </div>
                    </div>

                    <div class="col-md-12" *ngIf="quotaAbo.provider === 'google' && quotaAbo.lastRealtimeStatus">
                        <div class="form-group">
                            <label>{{ "abousers.props.lastRealtimeStatus" | translate }}</label>
                            <input name="lastRealtimeStatus_{{ idx }}" type="text" class="form-control" [ngModel]="'abousers.googleRTstatus.' + quotaAbo.lastRealtimeStatus | translate" readonly="readonly" />
                            <i class="form-group__bar"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- video subscriptions -->
    <div *ngIf="aboUser">
        <div class="card" *ngFor="let videoAbo of aboUser.userAboInfo?.video ?? []; let idx = index">
            <div class="card-body">
                <h3 class="card-body__title">
                    {{ "abousers.props.video" | translate }} {{ idx + 1 }}<span *ngIf="videoAbo.productId"> ({{ videoAbo.productId }})</span>:
                </h3>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ "abousers.props.provider" | translate }}</label>
                            <input name="provider_{{ idx }}" type="text" class="form-control" [ngModel]="videoAbo.provider" readonly="readonly" />
                            <i class="form-group__bar"></i>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ "abousers.props.lastBillingDate" | translate }}</label>
                            <input name="lastBillingDate_{{ idx }}" type="text" class="form-control" [ngModel]="videoAbo.lastBillingDate | date : UTCDATEFORMAT" readonly="readonly" />
                            <i class="form-group__bar"></i>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ "abousers.props.nextBillingDate" | translate }}</label>
                            <input name="nextBillingDate_{{ idx }}" type="text" class="form-control" [ngModel]="videoAbo.nextBillingDate | date : UTCDATEFORMAT" readonly="readonly" />
                            <i class="form-group__bar"></i>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ "abousers.props.active" | translate }}</label>
                            <div class="row">
                                <div class="col-12">
                                    <div class="toggle-switch">
                                        <input type="checkbox" class="toggle-switch__checkbox" [ngModel]="videoAbo.active" id="isHighRes_{{ idx }}" name="isHighRes_{{ idx }}" [disabled]="true" />
                                        <i class="toggle-switch__helper"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4" *ngIf="!videoAbo.active">
                        <div class="form-group">
                            <label>{{ "abousers.props.canceled" | translate }}</label>
                            <input name="canceled_{{ idx }}" type="text" class="form-control" [ngModel]="videoAbo.canceled | date : UTCDATEFORMAT" readonly="readonly" />
                            <i class="form-group__bar"></i>
                        </div>
                    </div>

                    <div class="col-md-4" *ngIf="!videoAbo.active">
                        <div class="form-group">
                            <label>{{ "abousers.props.cancelReason" | translate }}</label>
                            <textarea name="cancelReason_{{ idx }}" type="text" class="form-control" [ngModel]="videoAbo.cancelReason" readonly="readonly"></textarea>
                            <i class="form-group__bar"></i>
                        </div>
                    </div>

                    <div class="col-md-12" *ngIf="videoAbo.provider === 'google' && videoAbo.lastRealtimeStatus">
                        <div class="form-group">
                            <label>{{ "abousers.props.lastRealtimeStatus" | translate }}</label>
                            <input name="lastRealtimeStatus_{{ idx }}" type="text" class="form-control" [ngModel]="'abousers.googleRTstatus.' + videoAbo.lastRealtimeStatus | translate" readonly="readonly" />
                            <i class="form-group__bar"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
