import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { DataTablesModule } from "angular-datatables";

import { FirmwaresComponent } from "./firmwares.component";
import { FirmwaresService, FirmwareConfigService, IFirmwareConfigService } from "./firmwares.service";
import { FirmwareDetailComponent } from "./firmwaredetail.component";
import { TranslateModule } from "@ngx-translate/core";
import { OAuthModule } from "@impacgroup/angular-oauth-base";
import { Route, RouterModule } from "@angular/router";
import { FirmwaresMainComponent } from "./firmwares.main.component";
import { FirmwareApnsComponent } from "./firmwareapns.component";

@NgModule({
    declarations: [FirmwaresMainComponent, FirmwaresComponent, FirmwareDetailComponent, FirmwareApnsComponent],
    imports: [CommonModule, RouterModule, FormsModule, DataTablesModule, BsDropdownModule, ModalModule, NgSelectModule, TranslateModule, OAuthModule],
    exports: [],
    providers: []
})
export class FirmwaresModule {
    static forRoot(firmwareConfig: IFirmwareConfigService): ModuleWithProviders<FirmwaresModule> {
        // returns the services as singletons throughout the app (for lazy loading purposes)
        return {
            ngModule: FirmwaresModule,
            providers: [
                FirmwaresService,
                {
                    provide: FirmwareConfigService,
                    useValue: firmwareConfig
                }
            ]
        };
    }

    static getRoutes(): Route[] {
        return [
            {
                path: "",
                component: FirmwaresMainComponent,
                children: [
                    {
                        path: "overview",
                        component: FirmwaresComponent
                    },
                    {
                        path: "overview/add",
                        component: FirmwareDetailComponent
                    },
                    {
                        path: "overview/:id",
                        component: FirmwareDetailComponent
                    },

                    {
                        path: "apns",
                        component: FirmwareApnsComponent
                    }
                ]
            }
        ];
    }
}
