<div class="content__inner">
    <header class="content__title">
        <h1>{{ "stolencameras.list.title" | translate }}</h1>
        <small>{{ "stolencameras.list.subtitle" | translate }}</small>

        <div class="actions"></div>
    </header>

    <div class="card" *appHasPermission="'STOLENCAMERAS.LIST'">
        <div class="card-body">
            <h4 class="card-title">{{ "stolencameras.list.card.title" | translate }}</h4>

            <table datatable class="table table-bordered" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th id="camowner">{{ "stolencameras.props.camowner" | translate }}</th>
                        <th id="camera">{{ "stolencameras.props.camera" | translate }}</th>
                        <th id="city">{{ "stolencameras.props.city" | translate }}</th>
                        <th id="country">{{ "stolencameras.props.country" | translate }}</th>
                        <th id="created">{{ "stolencameras.props.created" | translate }}</th>
                        <th id="updated">{{ "stolencameras.props.updated" | translate }}</th>
                        <th id="actions"></th>
                    </tr>
                </thead>
                <tbody *ngIf="rows?.length != 0">
                    <tr *ngFor="let row of rows">
                        <td>{{ row.getCamOwnerDetails() }}</td>
                        <td>{{ row.getCameraDetails() }}</td>
                        <td>{{ row.city }}</td>
                        <td>{{ row.country }}</td>
                        <td>{{ row.created | date: UTCDATEFORMAT:"+0" }}</td>
                        <td>{{ row.updated | date: UTCDATEFORMAT:"+0" }}</td>
                        <td class="text-right nowrap">
                            <i (click)="editStolenCamera(row._id)" class="zmdi zmdi-edit zmdi-hc-fw tableIcon" *appHasPermission="'STOLENCAMERAS.EDIT'"></i>
                            <i (click)="deleteStolenCamera(row._id)" class="zmdi zmdi-delete zmdi-hc-fw tableIcon" *appHasPermission="'STOLENCAMERAS.DELETE'"></i>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="rows?.length == 0">
                    <tr>
                        <td colspan="7" class="no-data-available">{{ "global.datatable.empty" | translate }}</td>
                    </tr>
                </tbody>

                <tbody></tbody>
            </table>
        </div>
    </div>
</div>
