import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { DataTablesModule } from "angular-datatables";

import { StolenCamerasComponent } from "./stolencameras.component";
import { StolenCamerasService, StolenCameraConfigService, IStolenCameraConfigService } from "./stolencameras.service";
import { StolenCameraDetailComponent } from "./stolencameradetail.component";
import { TranslateModule } from "@ngx-translate/core";
import { OAuthModule } from "@impacgroup/angular-oauth-base";
import { Route, RouterModule } from "@angular/router";
import { StolenCamerasMainComponent } from "./stolencameras.main.component";
import { StandardCountryService } from "../../global/services/country.service";

@NgModule({
    declarations: [StolenCamerasMainComponent, StolenCamerasComponent, StolenCameraDetailComponent],
    imports: [CommonModule, RouterModule, FormsModule, DataTablesModule, BsDropdownModule, ModalModule, NgSelectModule, TranslateModule, OAuthModule],
    exports: [],
    providers: []
})
export class StolenCamerasModule {
    static forRoot(stolenCameraConfig: IStolenCameraConfigService): ModuleWithProviders<StolenCamerasModule> {
        // returns the services as singletons throughout the app (for lazy loading purposes)
        return {
            ngModule: StolenCamerasModule,
            providers: [
                StandardCountryService,
                StolenCamerasService,
                {
                    provide: StolenCameraConfigService,
                    useValue: stolenCameraConfig
                }
            ]
        };
    }

    static getRoutes(): Route[] {
        return [
            {
                path: "",
                component: StolenCamerasMainComponent,
                children: [
                    {
                        path: "",
                        component: StolenCamerasComponent
                    },
                    {
                        path: "add/:cameraId",
                        component: StolenCameraDetailComponent
                    },
                    {
                        path: ":id",
                        component: StolenCameraDetailComponent
                    }
                ]
            }
        ];
    }
}
