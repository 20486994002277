import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { BaseComponent, BaseComponentMode } from "@impacgroup/angular-baselib";
import { AboUsersService } from "./abousers.service";
import { AboUser } from "../../api-models/AboUser";

@Component({
    selector: "app-abouserdetail",
    templateUrl: "./abouserdetail.component.html",
    styleUrls: ["abouserdetail.component.scss"]
})
export class AboUserDetailComponent extends BaseComponent implements OnInit {
    private aboUserId?: string;
    public aboUser?: AboUser;
    public highResDeleteDate?: Date | null;
    public highResCamResetDate?: Date | null;

    public UTCDATEFORMAT?: string;

    constructor(private route: ActivatedRoute, private aboUsersService: AboUsersService, private router: Router, private _location: Location) {
        super();
        this.UTCDATEFORMAT = this.aboUsersService.UTCDATEFORMAT;

        this.subscriptions.push(
            this.route.params.subscribe((params) => {
                this.aboUserId = params["id"];
                if (this.aboUserId) {
                    this.initViewMode();
                }
            })
        );
    }

    ngOnInit(): void {}

    initViewMode() {
        this.MODE = BaseComponentMode.VIEW;

        if (!this.aboUserId) {
            return;
        }

        this.subscriptions.push(
            this.aboUsersService.aboUserDetail(this.aboUserId).subscribe({
                next: (result) => {
                    this.aboUser = result;
                    this.highResDeleteDate = this.aboUser.getHighResDeletionDate();
                    this.highResCamResetDate = this.aboUser.getHighResCamResetDate();
                },
                error: (err) => {
                    this.router.navigate(["../"], { relativeTo: this.route });
                }
            })
        );
    }

    back() {
        this._location.back();
    }
}
