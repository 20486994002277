import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PermissionService } from "@impacgroup/angular-oauth-base";
import { AlertService, BaseComponent, ConfirmDialogComponent } from "@impacgroup/angular-baselib";
import { CamerasService } from "../cameras.service";
import { TranslateService } from "@ngx-translate/core";
import { allCountries, Camera, CameraResolution } from "src/app/api-models/Camera";
import { CameraMode, PictureSize, VideoSize, MultiShot, NightMode, FlashLED, SDSycle, PIRSensitivity, PIRSwitch } from "../../../api-models/ICameraSettings";
import { BsModalService } from "ngx-bootstrap/modal";
import * as moment from "moment";
import * as googlelibPhoneNumber from "google-libphonenumber";
import { CameraCreateOrReplaceOutputDTO } from "../cameras.service.dto";

interface ISelectObj {
    key: any;
    value: any;
}

@Component({
    selector: "app-camera-data-and-settings-4g-v1v2",
    templateUrl: "./cameradataandsettings.component.html",
    styleUrls: ["cameradataandsettings.component.scss"]
})
export class CameraDataAndSettingsComponent extends BaseComponent implements OnChanges {
    @Input()
    public camera?: Camera | CameraCreateOrReplaceOutputDTO;

    /**
     * Show reconnect button
     */
    @Input()
    public showReconnectButton: boolean = true;

    /**
     * Show stolen button, if permissions allows it
     */
    @Input()
    public showStolenButtons: boolean = true;

    /**
     * Will be emitted after reconnection command send successful
     */
    @Output()
    public reconnectSendSuccessful = new EventEmitter<number>();

    /**
     * Will be emitted after save button was clicked and validation was successful
     */
    @Output()
    public saveClickedAndValid = new EventEmitter<number>();

    public countries: any[] = allCountries;

    // basedata edit permission
    public mayEditBasedata = false;

    // Settings - selectable values
    public cameraModes: ISelectObj[] = [];
    public pictureSizes: ISelectObj[] = [];
    public videoSizes: ISelectObj[] = [];
    public multiShots: ISelectObj[] = [];
    public nightModes: ISelectObj[] = [];
    public flashLEDs: ISelectObj[] = [];
    public sdSycles: ISelectObj[] = [];
    public pirSensitivities: ISelectObj[] = [];
    public pirSwitches: ISelectObj[] = [];

    // Timepicker values (needs to be converted to string in model)
    public delay: Date | null = null;
    public timeLapseHH: string | null = null;
    public timeLapseMM: string | null = null;
    public timeLapseSS: string | null = null;
    public workTimer1: Date | null = null;
    public workTimer2: Date | null = null;
    public workTimer1end: Date | null = null;
    public workTimer2end: Date | null = null;

    private phoneNumberUtil = googlelibPhoneNumber.PhoneNumberUtil.getInstance();

    ngOnChanges(changes: SimpleChanges) {
        if (changes["camera"]) {
            this.cameraChanged();
        }
    }

    constructor(
        private route: ActivatedRoute,
        private camerasService: CamerasService,
        private router: Router,
        private alertService: AlertService,
        private modalService: BsModalService,
        private translateService: TranslateService,
        private permissionService: PermissionService
    ) {
        super();

        this.mayEditBasedata = this.permissionService.hasPermission("CAMERAS.EDIT.BASEDATA");

        // Settings - selectable values
        this.numberEnumsToISelectObj(CameraMode, this.cameraModes);
        this.numberEnumsToISelectObj(PictureSize, this.pictureSizes);
        this.numberEnumsToISelectObj(VideoSize, this.videoSizes);
        this.numberEnumsToISelectObj(MultiShot, this.multiShots);
        this.numberEnumsToISelectObj(NightMode, this.nightModes);
        this.numberEnumsToISelectObj(FlashLED, this.flashLEDs);
        this.numberEnumsToISelectObj(SDSycle, this.sdSycles);
        this.numberEnumsToISelectObj(PIRSensitivity, this.pirSensitivities);
        this.numberEnumsToISelectObj(PIRSwitch, this.pirSwitches);
    }

    cameraChanged() {
        if (!this.camera || !this.camera.settings) {
            return;
        }

        if (this.camera.settings.delay) {
            this.delay = moment(this.camera.settings.delay, "HH:mm:ss").toDate();
        }
        if (this.camera.settings.timeLapse && this.camera.settings.timeLapse.split(":").length === 3) {
            const tmp = this.camera.settings.timeLapse.split(":");
            this.timeLapseHH = tmp[0];
            this.timeLapseMM = tmp[1];
            this.timeLapseSS = tmp[2];
        } else {
            this.timeLapseHH = this.timeLapseMM = this.timeLapseSS = null;
        }
        if (this.camera.settings.workTimer1) {
            this.workTimer1 = moment(this.camera.settings.workTimer1, "HH:mm").toDate();
        } else {
            this.workTimer1 = null;
        }
        if (this.camera.settings.workTimer2) {
            this.workTimer2 = moment(this.camera.settings.workTimer2, "HH:mm").toDate();
        } else {
            this.workTimer2 = null;
        }
        if (this.camera.settings.workTimer1end) {
            this.workTimer1end = moment(this.camera.settings.workTimer1end, "HH:mm").toDate();
        } else {
            this.workTimer1end = null;
        }
        if (this.camera.settings.workTimer2end) {
            this.workTimer2end = moment(this.camera.settings.workTimer2end, "HH:mm").toDate();
        } else {
            this.workTimer2end = null;
        }

        this.phoneOrPhoneRegionChanged();
    }

    phoneOrPhoneRegionChanged() {
        if (!this.camera || !this.camera.phone) {
            return;
        }

        try {
            const phone = this.phoneNumberUtil.parseAndKeepRawInput(this.camera.phone, this.camera.phoneRegion);
            this.camera.phoneFormatted = this.phoneNumberUtil.format(phone, googlelibPhoneNumber.PhoneNumberFormat.E164);
        } catch (e: any) {}
    }

    addStolenCamera() {
        if (this.camera?._id) {
            this.router.navigate(["../../../stolencameras/add/", this.camera._id], { relativeTo: this.route });
        }
    }

    editStolenCamera() {
        if (this.camera instanceof CameraCreateOrReplaceOutputDTO || !this.camera?.stolen) {
            return;
        }

        this.router.navigate(["../../../stolencameras/", this.camera.stolen], { relativeTo: this.route });
    }

    reconnectCamera() {
        const initialState = {
            title: this.translateService.instant("cameras.detail.edit.reconnect.title"),
            message: this.translateService.instant("cameras.detail.edit.reconnect.message"),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok")
        };
        const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
        if (bsModalRef.content) {
            bsModalRef.content.confirmCallback = () => {
                if (!this.camera?.productType || !this.camera?._id) {
                    return;
                }

                this.subscriptions.push(
                    this.camerasService.reconnectCamera({ type: this.camera.productType, id: this.camera._id }).subscribe((result) => {
                        this.alertService.addSuccess(this.translateService.instant("cameras.detail.edit.reconnect.success"));

                        this.reconnectSendSuccessful.emit();
                    })
                );
            };
        }
    }

    cameraMultishotInputPattern() {
        if (this.cameraMultishotHD()) {
            return "^([1-2])$";
        }
        return "^([1-5])$";
    }

    showAddStolenCameraButton() {
        if (this.camera instanceof CameraCreateOrReplaceOutputDTO) {
            return false;
        }

        return this.showStolenButtons && this.permissionService.hasPermission("STOLENCAMERAS.ADD") && !this.camera?.stolen;
    }

    showEditStolenCameraButton() {
        if (this.camera instanceof CameraCreateOrReplaceOutputDTO) {
            return false;
        }

        return this.showStolenButtons && this.permissionService.hasPermission("STOLENCAMERAS.EDIT") && this.camera?.stolen;
    }

    cameraMultishotHD() {
        if (this.camera instanceof CameraCreateOrReplaceOutputDTO) {
            return false;
        }

        if (this.camera?.isAboutToChangeResolution === CameraResolution.sd) {
            return false;
        }

        return this.camera?.isSendingHighRes === true || (this.camera?.isSendingHighRes === undefined && this.camera?.isHighRes === true) || this.camera?.isAboutToChangeResolution === CameraResolution.hd;
    }

    swapDoNotSendHighRes() {
        if (this.camera) {
            this.camera.doNotSendHighRes = !this.camera.doNotSendHighRes;
        }
    }

    save() {
        if (this.camera?.settings?.timeLapseActivated) {
            if (this.timeLapseHH === null || this.timeLapseHH === "") {
                this.alertService.addCustomErrorByI18nId("cameras.detail.validation.timeLapseHH_empty");
                return;
            }
            if (this.timeLapseMM === null || this.timeLapseMM === "") {
                this.alertService.addCustomErrorByI18nId("cameras.detail.validation.timeLapseMM_empty");
                return;
            }
            if (this.timeLapseSS === null || this.timeLapseSS === "") {
                this.alertService.addCustomErrorByI18nId("cameras.detail.validation.timeLapseSS_empty");
                return;
            }
            if (isNaN(this.timeLapseHH as any)) {
                this.alertService.addCustomErrorByI18nId("cameras.detail.validation.timeLapse_invalid_chars");
                return;
            }
            if (isNaN(this.timeLapseMM as any)) {
                this.alertService.addCustomErrorByI18nId("cameras.detail.validation.timeLapse_invalid_chars");
                return;
            }
            if (isNaN(this.timeLapseSS as any)) {
                this.alertService.addCustomErrorByI18nId("cameras.detail.validation.timeLapse_invalid_chars");
                return;
            }
            this.timeLapseHH = this.zeroFill(this.timeLapseHH, 2);
            this.timeLapseMM = this.zeroFill(this.timeLapseMM, 2);
            this.timeLapseSS = this.zeroFill(this.timeLapseSS, 2);
            if (parseInt(this.timeLapseHH, 10) > 24 || parseInt(this.timeLapseHH, 10) < 0) {
                this.alertService.addCustomErrorByI18nId("cameras.detail.validation.timeLapseHH_invalid_range");
                return;
            }
            if (parseInt(this.timeLapseMM, 10) > 59 || parseInt(this.timeLapseMM, 10) < 0) {
                this.alertService.addCustomErrorByI18nId("cameras.detail.validation.timeLapseMM_invalid_range");
                return;
            }
            if (parseInt(this.timeLapseSS, 10) > 59 || parseInt(this.timeLapseSS, 10) < 0) {
                this.alertService.addCustomErrorByI18nId("cameras.detail.validation.timeLapseSS_invalid_range");
                return;
            }
            // must be between 00:00:01 and 24:00:00
            const tmp = this.timeLapseHH + this.timeLapseMM + this.timeLapseSS;
            if (parseInt(tmp, 10) > 240000 || parseInt(tmp, 10) < 1) {
                this.alertService.addCustomErrorByI18nId("cameras.detail.validation.timeLapse_invalid_range");
                return;
            }
        }
        if (this.camera?.settings?.delayActivated) {
            if (this.delay) {
                this.camera.settings.delay = moment(this.delay).format("HH:mm:ss");
            } else {
                this.alertService.addCustomErrorByI18nId("cameras.detail.validation.delay_empty");
                return;
            }
        }
        if (this.camera?.settings?.timeLapseActivated) {
            this.camera.settings.timeLapse = this.timeLapseHH + ":" + this.timeLapseMM + ":" + this.timeLapseSS;
        }
        if (this.camera?.settings?.workTimer1Activated) {
            if (this.workTimer1) {
                this.camera.settings.workTimer1 = moment(this.workTimer1).format("HH:mm");
            } else {
                this.alertService.addCustomErrorByI18nId("cameras.detail.validation.work_timer_1_start_empty");
                return;
            }
            if (this.workTimer1end) {
                this.camera.settings.workTimer1end = moment(this.workTimer1end).format("HH:mm");
            } else {
                this.alertService.addCustomErrorByI18nId("cameras.detail.validation.work_timer_1_end_empty");
                return;
            }
        }
        if (this.camera?.settings?.workTimer2Activated) {
            if (this.workTimer2) {
                this.camera.settings.workTimer2 = moment(this.workTimer2).format("HH:mm");
            } else {
                this.alertService.addCustomErrorByI18nId("cameras.detail.validation.work_timer_2_start_empty");
                return;
            }
            if (this.camera.settings.workTimer2Activated && this.workTimer2end) {
                this.camera.settings.workTimer2end = moment(this.workTimer2end).format("HH:mm");
            } else {
                this.alertService.addCustomErrorByI18nId("cameras.detail.validation.work_timer_2_end_empty");
                return;
            }
        }

        if (!(this.camera instanceof CameraCreateOrReplaceOutputDTO)) {
            if (this.camera?.settings && this.camera.settings.multiShot < this.camera.settings.multishotUpload) {
                this.alertService.addCustomErrorByI18nId("cameras.detail.validation.multishotUploadVsMultiShot");
                return;
            }
        }

        this.saveClickedAndValid.emit();
    }

    private numberEnumsToISelectObj(T: any, list: ISelectObj[]) {
        for (const value in T) {
            if (typeof T[value] === "number") {
                list.push({
                    key: T[value],
                    value: this.translateService.instant("cameras.props.enums." + value)
                });
            }
        }
    }

    private zeroFill(obj: string, totalLength: number): string {
        let result = obj;
        while (result.length < totalLength) {
            result = "0" + result;
        }
        return result;
    }
}
