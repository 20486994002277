import { Component, OnInit, Host } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { FirmwaresService } from "./firmwares.service";
import { AlertService, BaseDatatableComponent, ConfirmDialogComponent, DataTableParameters } from "@impacgroup/angular-baselib";
import { Firmware } from "../../api-models/Firmware";
import { FirmwaresMainComponent } from "./firmwares.main.component";

@Component({
    selector: "app-firmwares",
    templateUrl: "./firmwares.component.html",
    styleUrls: ["firmwares.component.scss"]
})
export class FirmwaresComponent extends BaseDatatableComponent implements OnInit {
    public UTCDATEFORMAT: string = "";

    rows: Firmware[] = [];

    constructor(
        private firmwaresService: FirmwaresService,
        private translateService: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: BsModalService,
        private alertService: AlertService,
        parent: FirmwaresMainComponent
    ) {
        super(translateService, {
            stateSaveMode: firmwaresService.datatableStateSaveMode,
            stateSaveComponent: parent,
            stateSaveStorageKey: "dtstate_firmwares_list"
        });

        this.dtOptions.order = [[5, "desc"]];
        this.UTCDATEFORMAT = this.firmwaresService.UTCDATEFORMAT;
    }

    ngOnInit() {}

    _dataTableTrigger() {
        return (dataTableParameters: DataTableParameters, callback: Function) => {
            // get api query parameters by datatable
            const queryParams = this.getApiQueryParamsByDataTable(dataTableParameters);

            this.subscriptions.push(
                this.firmwaresService.firmwares(queryParams).subscribe((result) => {
                    this.rows = result.list;

                    callback({
                        recordsTotal: result.total,
                        recordsFiltered: result.count,
                        data: []
                    });
                })
            );
        };
    }

    _dataTableColums() {
        return [
            { data: "name", orderable: true },
            { data: "originalname", orderable: true },
            { data: "productType", orderable: true },
            { data: "published", orderable: true },
            { data: "publishedBeta", orderable: true },
            { data: "created", orderable: true },
            { data: "updated", orderable: true },
            { data: "actions", orderable: false }
        ];
    }

    addFirmware() {
        this.router.navigate(["add"], { relativeTo: this.route });
    }

    editFirmware(id?: string) {
        this.router.navigate([id], { relativeTo: this.route });
    }

    deleteFirmware(id?: string) {
        const initialState = {
            title: this.translateService.instant("firmwares.list.delete.title"),
            message: this.translateService.instant("firmwares.list.delete.message"),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok")
        };
        const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
        if (bsModalRef.content) {
            bsModalRef.content.confirmCallback = () => {
                if (!id) {
                    return;
                }

                this.subscriptions.push(
                    this.firmwaresService.deleteFirmware(id).subscribe((result) => {
                        this.alertService.addSuccess(this.translateService.instant("firmwares.list.delete.success"));
                        this.rerenderDatatable();
                    })
                );
            };
        }
    }
}
