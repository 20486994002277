import { Component } from "@angular/core";
import { BaseComponent, IDatatableStateSaveableComponent } from "@impacgroup/angular-baselib";

@Component({
    selector: "app-firmwares-main",
    templateUrl: "./firmwares.main.component.html"
})
export class FirmwaresMainComponent extends BaseComponent implements IDatatableStateSaveableComponent {
    public listStateData: any;
}
