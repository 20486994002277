<div class="content__inner">
    <header class="content__title">
        <h1 *ngIf="MODE === 'ADD'">{{ "imeis.detail.add.title" | translate }}</h1>
        <h1 *ngIf="MODE === 'EDIT'">{{ "imeis.detail.edit.title" | translate }}</h1>

        <div class="actions">
            <button (click)="back()" class="btn btn-secondary btn--icon-text"><i class="zmdi zmdi-arrow-back"></i> {{ "global.buttons.back" | translate }}</button>
        </div>
    </header>

    <div class="card">
        <div class="card-body">
            <form name="form" class="row" *ngIf="imei" (ngSubmit)="f.form.valid && save()" #f="ngForm" novalidate>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "imeis.props.code" | translate }}</label>
                        <input name="code" type="text" class="form-control" [(ngModel)]="imei.code" #code="ngModel" [ngClass]="{ 'is-invalid': f.submitted && code.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ "imeis.detail.validation.code" | translate }}
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "imeis.props.productType" | translate }}</label>
                        <ng-select
                            name="productType"
                            [items]="productTypes"
                            placeholder=""
                            bindLabel="name"
                            bindValue="key"
                            [clearable]="false"
                            [(ngModel)]="imei.productType"
                            #productType="ngModel"
                            [ngClass]="{ 'is-invalid': f.submitted && productType.invalid }"
                            required
                        >
                        </ng-select>
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ "imeis.detail.validation.productType" | translate }}
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <button class="float-right btn btn-success btn--icon-text" [disabled]="f.submitted && f.invalid"><i class="zmdi zmdi-check"></i> {{ "global.buttons.save" | translate }}</button>
                </div>
            </form>
        </div>
    </div>
</div>
