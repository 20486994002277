import { Component, OnInit, Host } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { CamerasService } from "./cameras.service";
import { AlertService, BaseDatatableComponent, ConfirmDialogComponent, DataTableParameters } from "@impacgroup/angular-baselib";
import { CamerasMainComponent } from "./cameras.main.component";
import { CameraApiServiceListResponseDTO } from "./cameras.service.dto";

@Component({
    selector: "app-cameras",
    templateUrl: "./cameras.component.html",
    styleUrls: ["cameras.component.scss"]
})
export class CamerasComponent extends BaseDatatableComponent implements OnInit {
    public UTCDATEFORMAT: string = "";

    rows: CameraApiServiceListResponseDTO[] = [];

    constructor(
        private camerasService: CamerasService,
        private translateService: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: BsModalService,
        private alertService: AlertService,
        parent: CamerasMainComponent
    ) {
        super(translateService, {
            stateSaveMode: camerasService.datatableStateSaveMode,
            stateSaveComponent: parent,
            stateSaveStorageKey: "dtstate_cameras_list"
        });

        this.dtOptions.order = [[5, "desc"]];
        this.UTCDATEFORMAT = this.camerasService.UTCDATEFORMAT;
    }

    ngOnInit() {}

    _dataTableTrigger() {
        return (dataTableParameters: DataTableParameters, callback: Function) => {
            // get api query parameters by datatable
            const queryParams = this.getApiQueryParamsByDataTable(dataTableParameters);

            this.subscriptions.push(
                this.camerasService.cameras(queryParams).subscribe((result) => {
                    this.rows = result.list;

                    callback({
                        recordsTotal: result.total,
                        recordsFiltered: result.count,
                        data: []
                    });
                })
            );
        };
    }

    _dataTableColums() {
        return [
            { data: "unverifiedBackofficeRegisteredCamera", orderable: false },
            { data: "imei", orderable: true },
            { data: "name", orderable: true },
            { data: "productType", orderable: true },
            { data: "user", orderable: false },
            { data: "created", orderable: true },
            { data: "updated", orderable: true },
            { data: "actions", orderable: false }
        ];
    }

    addCamera() {
        this.router.navigate(["add"], { relativeTo: this.route });
    }

    editCamera(type?: string, id?: string) {
        this.router.navigate([type, id], { relativeTo: this.route });
    }

    deleteCamera(type?: string, id?: string) {
        const initialState = {
            title: this.translateService.instant("cameras.list.delete.title"),
            message: this.translateService.instant("cameras.list.delete.message"),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok")
        };
        const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
        if (bsModalRef.content) {
            bsModalRef.content.confirmCallback = () => {
                if (!type || !id) {
                    return;
                }

                this.subscriptions.push(
                    this.camerasService.deletecamera({ type, id }).subscribe((result) => {
                        this.alertService.addSuccess(this.translateService.instant("cameras.list.delete.success"));
                        this.rerenderDatatable();
                    })
                );
            };
        }
    }
}
