import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorageAdapter } from "@impacgroup/angular-oauth-base";
import { ApiListQueryParameter, BaseDatatableStateSaveMode } from "@impacgroup/angular-baselib";
import { map } from "rxjs/operators";
import { instanceToPlain, plainToInstance } from "class-transformer";
import { Imei } from "../../api-models/Imei";

export interface IImeiConfigService {
    imeisAPI: string;
    utcDateFormat: string;
    datatableStateSaveMode: BaseDatatableStateSaveMode;
}

export const ImeiConfigService = new InjectionToken<IImeiConfigService>("ImeiConfig");

@Injectable()
export class ImeisService {
    public UTCDATEFORMAT: string = "";
    public datatableStateSaveMode: BaseDatatableStateSaveMode;

    constructor(@Inject(ImeiConfigService) private imeiConfig: IImeiConfigService, private http: HttpClient, private oauthStorageAdapter: OAuthStorageAdapter) {
        this.UTCDATEFORMAT = this.imeiConfig.utcDateFormat;
        this.datatableStateSaveMode = this.imeiConfig.datatableStateSaveMode;
    }

    public imeis(params: ApiListQueryParameter): Observable<{ list: Imei[]; count: number; total: number }> {
        return this.http
            .get<any>(this.getImeisServiceUrl(""), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                params: params as any,
                observe: "response"
            })
            .pipe(
                map((result) => {
                    let count = result.body.length;
                    let total = result.body.length;
                    try {
                        count = parseInt(result.headers.get("X-Pagination-Count") ?? "0", 10);
                    } catch (e) {}
                    try {
                        total = parseInt(result.headers.get("X-Total-Count") ?? "0", 10);
                    } catch (e) {}

                    return {
                        list: plainToInstance(Imei, result.body as any[]),
                        count: count,
                        total: total
                    };
                })
            );
    }

    public imeidetail(id: string): Observable<Imei> {
        return this.http
            .get<Object>(this.getImeisServiceUrl(id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToInstance(Imei, result)));
    }

    public addimei(imei: Imei): Observable<Imei> {
        return this.http
            .post<Object>(this.getImeisServiceUrl(""), instanceToPlain(imei), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToInstance(Imei, result)));
    }

    public updateimei(imei: Imei): Observable<Imei> {
        if (!imei._id) {
            throw new Error("Cannot update object without _id");
        }

        return this.http
            .patch<Object>(this.getImeisServiceUrl(imei._id), instanceToPlain(imei), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToInstance(Imei, result)));
    }

    public deleteimei(id: string): Observable<Imei> {
        return this.http
            .delete<Object>(this.getImeisServiceUrl(id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToInstance(Imei, result)));
    }

    public bulkCreate(imeis: Object[]): Observable<void> {
        return this.http.post<void>(this.getImeisServiceUrl("bulk"), imeis, {
            headers: this.oauthStorageAdapter.getAuthHeadersJSON()
        });
    }

    private getImeisServiceUrl(postfix: string): string {
        return this.imeiConfig.imeisAPI + postfix;
    }
}
