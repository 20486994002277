export enum CameraMode {
    photo = 1,
    picAndVideo = 3
}

export enum PictureSize {
    m12 = 1,
    m8 = 2,
    m5 = 3
}

export enum VideoSize {
    fhd1080p = 1,
    hd720p = 2,
    wvga = 3
}

export enum MultiShot {
    p1 = 1,
    p2 = 2,
    p3 = 3,
    p4 = 4,
    p5 = 5
}

export enum NightMode {
    maxRange = 1,
    balanced = 2,
    minRange = 3
}

export enum FlashLED {
    all = 0,
    half = 1
}

export enum SDSycle {
    on = 1,
    off = 0
}

export enum PIRSensitivity {
    high = 0,
    middle = 1,
    low = 2
}

export enum PIRSwitch {
    on = 0,
    off = 1
}

export interface ICameraSettings {
    cameraMode: CameraMode;
    pictureSize: PictureSize;
    videoSize: VideoSize;
    videoLength: number;
    multiShot: MultiShot;
    nightMode: NightMode;
    flashLED: FlashLED;
    sdSycle: SDSycle;
    pirSensitivity: PIRSensitivity;
    pirSwitch: PIRSwitch;
    delayActivated: boolean; // SMS value should ne "OFF" if false
    delay: string;
    timeLapseActivated: boolean; // SMS value should ne "OFF" if false
    timeLapse: string;
    workTimer1Activated: boolean; // SMS value should ne "OFF" if false
    workTimer1: string;
    workTimer1end: string;
    workTimer2Activated: boolean; // SMS value should ne "OFF" if false
    workTimer2: string;
    workTimer2end: string;
    confirmedByCam: boolean;
    multishotUpload: number;
}

export const getDefaultCameraSettings = (): ICameraSettings => {
    return {
        cameraMode: 1,
        pictureSize: 1,
        videoSize: 2,
        videoLength: 5,
        multiShot: 1,
        multishotUpload: 1,
        nightMode: 2,
        flashLED: 0,
        sdSycle: 1,
        pirSensitivity: 0,
        pirSwitch: 0,
        delayActivated: true, // SMS value should ne "OFF" if false
        delay: "00:01:00",
        timeLapseActivated: false, // SMS value should ne "OFF" if false
        timeLapse: "",
        workTimer1Activated: false, // SMS value should ne "OFF" if false
        workTimer1: "",
        workTimer1end: "",
        workTimer2Activated: false, // SMS value should ne "OFF" if false
        workTimer2: "",
        workTimer2end: "",
        confirmedByCam: false
    };
};
