<div class="content__inner">
    <header class="content__title">
        <h1>{{ "abousers.list.title" | translate }}</h1>
        <small>{{ "abousers.list.subtitle" | translate }}</small>
    </header>

    <div class="card" *appHasPermission="'ABOUSERS.LIST'">
        <div class="card-body">
            <h4 class="card-title">{{ "abousers.list.card.title" | translate }}</h4>

            <table datatable class="table table-bordered" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th id="email">{{ "abousers.props.email" | translate }}</th>
                        <th id="firstname">{{ "abousers.props.firstname" | translate }}</th>
                        <th id="lastname">{{ "abousers.props.lastname" | translate }}</th>
                        <th id="created">{{ "abousers.props.created" | translate }}</th>
                        <th id="updated">{{ "abousers.props.updated" | translate }}</th>
                        <th id="actions"></th>
                    </tr>
                </thead>
                <tbody *ngIf="rows?.length != 0">
                    <tr *ngFor="let row of rows">
                        <td>{{ row.email }}</td>
                        <td>{{ row.firstname }}</td>
                        <td>{{ row.lastname }}</td>
                        <td>{{ row.created | date: UTCDATEFORMAT:"+0" }}</td>
                        <td>{{ row.updated | date: UTCDATEFORMAT:"+0" }}</td>
                        <td class="text-right nowrap">
                            <i (click)="viewAboUser(row._id)" class="zmdi zmdi-eye zmdi-hc-fw tableIcon" *appHasPermission="'ABOUSERS.VIEW'"></i>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="rows?.length == 0">
                    <tr>
                        <td colspan="6" class="no-data-available">{{ "global.datatable.empty" | translate }}</td>
                    </tr>
                </tbody>

                <tbody></tbody>
            </table>
        </div>
    </div>
</div>
