import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { DataTablesModule } from "angular-datatables";

import { ImeisComponent } from "./imeis.component";
import { ImeisService, ImeiConfigService, IImeiConfigService } from "./imeis.service";
import { ImeidetailComponent } from "./imeidetail.component";
import { TranslateModule } from "@ngx-translate/core";
import { OAuthModule } from "@impacgroup/angular-oauth-base";
import { Route, RouterModule } from "@angular/router";
import { ImeisMainComponent } from "./imeis.main.component";

@NgModule({
    declarations: [ImeisMainComponent, ImeisComponent, ImeidetailComponent],
    imports: [CommonModule, RouterModule, FormsModule, DataTablesModule, BsDropdownModule, ModalModule, NgSelectModule, TranslateModule, OAuthModule],
    exports: [],
    providers: []
})
export class ImeisModule {
    static forRoot(imeiConfig: IImeiConfigService): ModuleWithProviders<ImeisModule> {
        // returns the services as singletons throughout the app (for lazy loading purposes)
        return {
            ngModule: ImeisModule,
            providers: [
                ImeisService,
                {
                    provide: ImeiConfigService,
                    useValue: imeiConfig
                }
            ]
        };
    }

    static getRoutes(): Route[] {
        return [
            {
                path: "",
                component: ImeisMainComponent,
                children: [
                    {
                        path: "",
                        component: ImeisComponent
                    },
                    {
                        path: "add",
                        component: ImeidetailComponent
                    },
                    {
                        path: ":id",
                        component: ImeidetailComponent
                    }
                ]
            }
        ];
    }
}
