import { Exclude, Type } from "class-transformer";

export interface IStolenCameraOwner {
    _id: string;
    email: string;
    firstname: string;
    lastname: string;
}

export interface IStolenCameraCam {
    _id: string;
    imei: string;
    name: string;
}

export class StolenCamera {
    _id?: string;

    firstname?: string;
    lastname?: string;
    phone?: string;
    address?: string;
    zip?: string;
    city?: string;
    country?: string;
    email?: string;
    notes?: string;
    camera?: IStolenCameraCam | string;

    @Exclude({ toPlainOnly: true })
    mailsSent?: string[];

    @Exclude({ toPlainOnly: true })
    imei?: string;

    @Exclude({ toPlainOnly: true })
    camowner?: IStolenCameraOwner;

    version?: number;

    @Type(() => Date)
    @Exclude({ toPlainOnly: true })
    created?: Date;

    @Type(() => Date)
    @Exclude({ toPlainOnly: true })
    updated?: Date;

    public getCameraDetails(): string {
        if (this.camera && (this.camera as IStolenCameraCam).imei) {
            return `${String((this.camera as IStolenCameraCam).imei)} (${String((this.camera as IStolenCameraCam).name)})`;
        }
        return String(this.imei);
    }

    public getCamOwnerDetails() {
        if (this.camowner && (this.camowner as IStolenCameraOwner).email) {
            return `${String((this.camowner as IStolenCameraOwner).email)} (${String((this.camowner as IStolenCameraOwner).firstname)} ${String((this.camowner as IStolenCameraOwner).lastname)})`;
        }
        return "";
    }
}
