import { Expose, Transform, Type } from "class-transformer";

import { Camera } from "src/app/api-models/Camera";
import { ICameraSettings } from "src/app/api-models/ICameraSettings";
import { ProductTypeKey } from "@impacgroup/doerr-wildkamera-api-shared";

/**
 * Camera imei informations
 * used in: CamerasService.getImeiInformations
 */
export type ImeiStatus = "imeiInvalid" | "cameraStolen" | "imeiAssigned" | "imeiDoesNotExist" | "imeiExists";

export class UserCameraListResponseDTO {
    @Expose()
    @Transform(({ key, obj }) => (obj[key] !== undefined && obj[key] !== null ? String(obj[key]) : undefined))
    _id?: string;

    @Expose()
    @Transform(({ key, obj }) => (obj[key] !== undefined && obj[key] !== null ? String(obj[key]) : undefined))
    user?: string;

    @Expose()
    imei?: string;

    @Expose()
    name?: string;

    @Expose()
    phone?: string;

    @Expose()
    productType?: ProductTypeKey;
}

export class CameraImeiInformationsDTO {
    status?: ImeiStatus;
    productType?: ProductTypeKey;
}

/**
 * Camera add/replace DTO
 * used in: CamerasService.createOrReplaceCamera
 *          CameracreateComponent['camera']
 *          CameracreateComponent['newCamera']
 *          CameracreateComponent['replacementCamera']
 *          CameracreateComponent.cameraToReplaceSelected
 *          CameracreateComponent.createDefaultCamera
 *          CamerasService.createOrReplaceCamera
 */
export class CameraCreateOrReplaceOutputDTO {
    @Expose()
    public _id: Camera["_id"];

    @Expose()
    public productType: Camera["productType"];

    @Expose()
    public userId?: string;

    @Expose()
    public imei?: Camera["imei"];

    @Expose()
    public name?: Camera["name"];

    @Expose()
    public phone?: Camera["phone"];

    @Expose()
    public phoneRegion?: Camera["phoneRegion"];

    @Expose()
    public phoneFormatted?: Camera["phoneFormatted"];

    @Expose()
    @Type(() => CameraCreateOrReplaceSettingsOutputDTO)
    public settings?: CameraCreateOrReplaceSettingsOutputDTO;

    @Expose()
    public doNotSendHighRes?: Camera["doNotSendHighRes"];

    @Expose()
    public gpsActive?: Camera["gpsActive"];

    @Expose()
    public unverifiedBackofficeRegisteredCamera?: Camera["unverifiedBackofficeRegisteredCamera"];

    @Expose()
    public version?: Camera["version"];
}

/**
 * Camera add/replace DTO
 * used in CameraCreateOrReplaceOutputDTO
 */
export class CameraCreateOrReplaceSettingsOutputDTO {
    @Expose()
    public cameraMode?: ICameraSettings["cameraMode"];

    @Expose()
    public pictureSize?: ICameraSettings["pictureSize"];

    @Expose()
    public videoSize?: ICameraSettings["videoSize"];

    @Expose()
    public videoLength?: ICameraSettings["videoLength"];

    @Expose()
    public multiShot?: ICameraSettings["multiShot"];

    @Expose()
    public nightMode?: ICameraSettings["nightMode"];

    @Expose()
    public flashLED?: ICameraSettings["flashLED"];

    @Expose()
    public sdSycle?: ICameraSettings["sdSycle"];

    @Expose()
    public pirSensitivity?: ICameraSettings["pirSensitivity"];

    @Expose()
    public pirSwitch?: ICameraSettings["pirSwitch"];

    @Expose()
    public delayActivated?: ICameraSettings["delayActivated"]; // SMS value should ne "OFF" if false

    @Expose()
    public delay?: ICameraSettings["delay"];

    @Expose()
    public timeLapseActivated?: ICameraSettings["timeLapseActivated"]; // SMS value should ne "OFF" if false

    @Expose()
    public timeLapse?: ICameraSettings["timeLapse"];

    @Expose()
    public workTimer1Activated?: ICameraSettings["workTimer1Activated"]; // SMS value should ne "OFF" if false

    @Expose()
    public workTimer1?: ICameraSettings["workTimer1"];

    @Expose()
    public workTimer1end?: ICameraSettings["workTimer1end"];

    @Expose()
    public workTimer2Activated?: ICameraSettings["workTimer2Activated"]; // SMS value should ne "OFF" if false

    @Expose()
    public workTimer2?: ICameraSettings["workTimer2"];

    @Expose()
    public workTimer2end?: ICameraSettings["workTimer2end"];

    @Expose()
    public confirmedByCam?: ICameraSettings["confirmedByCam"];

    @Expose()
    public multishotUpload?: ICameraSettings["multishotUpload"];
}

// ---------------- used by camera list  ---------------- //
export class CameraApiServiceListResponseUserDTO {
    @Expose()
    _id?: string;

    @Expose()
    email?: string;

    @Expose()
    firstname?: string;

    @Expose()
    lastname?: string;
}
export class CameraApiServiceListResponseDTO {
    @Expose()
    _id?: string;

    @Expose()
    imei?: string;

    @Expose()
    name?: string;

    @Expose()
    @Type(() => CameraApiServiceListResponseUserDTO)
    user?: CameraApiServiceListResponseUserDTO;

    @Expose()
    unverifiedBackofficeRegisteredCamera?: boolean;

    @Expose()
    productType?: ProductTypeKey;

    @Expose()
    @Type(() => Date)
    created?: Date;

    @Expose()
    @Type(() => Date)
    updated?: Date;

    public getUser(): string {
        if (this.user) {
            return (this.user.firstname && this.user.lastname ? `${this.user.firstname} ${this.user.lastname} (${this.user.email})` : this.user.email) ?? "";
        }
        return "";
    }
}
// END ---------------- used by camera list  ---------------- //
