<div class="content__inner">
    <header class="content__title">
        <h1>{{ "cameras.list.title" | translate }}</h1>
        <small>{{ "cameras.list.subtitle" | translate }}</small>

        <div class="actions">
            <button *appHasPermission="'CAMERAS.ADD'" (click)="addCamera()" class="btn btn-success btn--icon-text" style="margin-left: 25px"><i class="zmdi zmdi-collection-plus"></i> {{ "cameras.list.add" | translate }}</button>
        </div>
    </header>

    <div class="card" *appHasPermission="'CAMERAS.LIST'">
        <div class="card-body">
            <h4 class="card-title">{{ "cameras.list.card.title" | translate }}</h4>

            <table datatable class="table table-bordered" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th id="unverifiedBackofficeRegisteredCamera"></th>
                        <th id="imei">{{ "cameras.props.imei" | translate }}</th>
                        <th id="name">{{ "cameras.props.name" | translate }}</th>
                        <th id="productType">{{ "cameras.props.productType" | translate }}</th>
                        <th id="user">{{ "cameras.props.user" | translate }}</th>
                        <th id="created">{{ "cameras.props.created" | translate }}</th>
                        <th id="updated">{{ "cameras.props.updated" | translate }}</th>
                        <th id="actions"></th>
                    </tr>
                </thead>
                <tbody *ngIf="rows?.length != 0">
                    <tr *ngFor="let row of rows">
                        <td class="text-center">
                            <i
                                class="unverifiedBackofficeRegisteredCameraIcon zmdi zmdi-cloud-off"
                                *ngIf="row.unverifiedBackofficeRegisteredCamera"
                                [popover]="statusTemplate"
                                [popoverContext]="{ row: row }"
                                placement="bottom"
                                triggers="mouseenter:mouseleave"
                            ></i>
                        </td>
                        <td>{{ row.imei }}</td>
                        <td>{{ row.name }}</td>
                        <td>{{ row.productType }}</td>
                        <td>{{ row.getUser() }}</td>
                        <td>{{ row.created | date : UTCDATEFORMAT : "+0" }}</td>
                        <td>{{ row.updated | date : UTCDATEFORMAT : "+0" }}</td>
                        <td class="text-right nowrap">
                            <i (click)="editCamera(row.productType, row._id)" class="zmdi zmdi-edit zmdi-hc-fw tableIcon" *appHasPermission="'CAMERAS.EDIT'"></i>
                            <i (click)="deleteCamera(row.productType, row._id)" class="zmdi zmdi-delete zmdi-hc-fw tableIcon" *appHasPermission="'CAMERAS.DELETE'"></i>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="rows?.length == 0">
                    <tr>
                        <td colspan="7" class="no-data-available">{{ "global.datatable.empty" | translate }}</td>
                    </tr>
                </tbody>

                <tbody></tbody>
            </table>
        </div>
    </div>

    <ng-template #statusTemplate let-row="row">
        <div class="status-popover">
            <b>{{ "cameras.props.unverified" | translate }}</b>
        </div>
    </ng-template>
</div>
