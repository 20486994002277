import { Exclude, Type } from "class-transformer";
import { ProductTypeKey } from "@impacgroup/doerr-wildkamera-api-shared";

export class Firmware {
    _id?: string;

    name?: string;
    productType?: ProductTypeKey;
    published: boolean = false;
    publishedBeta: boolean = false;
    imeiWhitelist: string[] = [];

    @Exclude({ toPlainOnly: true })
    originalname?: string;
    version?: number;

    @Type(() => Date)
    @Exclude({ toPlainOnly: true })
    created?: Date;

    @Type(() => Date)
    @Exclude({ toPlainOnly: true })
    updated?: Date;
}
