import { Component } from "@angular/core";
import { BaseComponent, IDatatableStateSaveableComponent } from "@impacgroup/angular-baselib";

@Component({
    selector: "app-abousers-main",
    templateUrl: "./abousers.main.component.html"
})
export class AboUsersMainComponent extends BaseComponent implements IDatatableStateSaveableComponent {
    public listStateData: any;
}
