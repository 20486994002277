import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorageAdapter, User } from "@impacgroup/angular-oauth-base";
import { ApiListQueryParameter, BaseDatatableStateSaveMode } from "@impacgroup/angular-baselib";
import { map } from "rxjs/operators";
import { instanceToPlain, plainToInstance } from "class-transformer";
import { Camera } from "../../api-models/Camera";
import { ICameraCorruptPicture } from "../../api-models/ICameraCorruptPicture";
import { CameraImeiInformationsDTO, CameraCreateOrReplaceOutputDTO, CameraApiServiceListResponseDTO } from "./cameras.service.dto";

export interface ICameraConfigService {
    camerasAPI: string;
    utcDateFormat: string;
    datatableStateSaveMode: BaseDatatableStateSaveMode;
}

export const CameraConfigService = new InjectionToken<ICameraConfigService>("CameraConfig");

@Injectable()
export class CamerasService {
    public UTCDATEFORMAT: string = "";
    public datatableStateSaveMode: BaseDatatableStateSaveMode;

    constructor(@Inject(CameraConfigService) private cameraConfig: ICameraConfigService, private http: HttpClient, private oauthStorageAdapter: OAuthStorageAdapter) {
        this.UTCDATEFORMAT = this.cameraConfig.utcDateFormat;
        this.datatableStateSaveMode = this.cameraConfig.datatableStateSaveMode;
    }

    public cameras(params: ApiListQueryParameter): Observable<{ list: CameraApiServiceListResponseDTO[]; count: number; total: number }> {
        return this.http
            .get<object[]>(this.getCamerasServiceUrl(""), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                params: params as any,
                observe: "response"
            })
            .pipe(
                map((result) => {
                    let count = result.body?.length ?? 0;
                    let total = result.body?.length ?? 0;
                    try {
                        count = parseInt(result.headers.get("X-Pagination-Count") ?? "0", 10);
                    } catch (e) {}
                    try {
                        total = parseInt(result.headers.get("X-Total-Count") ?? "0", 10);
                    } catch (e) {}

                    return {
                        list: plainToInstance(CameraApiServiceListResponseDTO, result.body ?? []),
                        count: count,
                        total: total
                    };
                })
            );
    }

    public cameradetail({ id, type }: { id: string; type: string }): Observable<Camera> {
        return this.http
            .get<Object>(this.getCamerasServiceUrl(`${type}/${id}`), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToInstance(Camera, result)));
    }

    public createOrReplaceCamera(camera: CameraCreateOrReplaceOutputDTO): Observable<void> {
        if (!camera.productType) {
            throw new Error("Cannot update object productType");
        }

        return this.http.post<void>(this.getCamerasServiceUrl(camera.productType), instanceToPlain(camera), {
            headers: this.oauthStorageAdapter.getAuthHeadersJSON()
        });
    }

    public updatecamera(camera: Camera): Observable<Camera> {
        if (!camera.productType || !camera._id) {
            throw new Error("Cannot update object without _id or productType");
        }

        return this.http
            .patch<Object>(this.getCamerasServiceUrl(`${camera.productType}/${camera._id}`), instanceToPlain(camera), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToInstance(Camera, result)));
    }

    public deletecamera({ id, type }: { id: string; type: string }): Observable<Camera> {
        return this.http
            .delete<Object>(this.getCamerasServiceUrl(`${type}/${id}`), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToInstance(Camera, result)));
    }

    public reconnectCamera({ id, type }: { id: string; type: string }): Observable<Camera> {
        return this.http
            .put<Object>(
                this.getCamerasServiceUrl(`${type}/${id}/reconnect`),
                {},
                {
                    headers: this.oauthStorageAdapter.getAuthHeadersJSON()
                }
            )
            .pipe(map((result) => plainToInstance(Camera, result)));
    }

    public corruptPicturesStatistics({ start, end, type, id }: { start: Date; end: Date; type: string; id: string }): Observable<ICameraCorruptPicture[]> {
        return this.http.get<ICameraCorruptPicture[]>(this.getCamerasServiceUrl(`${type}/${id}/corrupt`), {
            headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
            params: {
                start: start.getTime(),
                end: end.getTime()
            } as any
        });
    }

    // TODO: use own DTO here instead of "User"
    public userSuggestions(params: ApiListQueryParameter): Observable<{ list: User[]; count: number; total: number }> {
        return this.http
            .get<any>(this.getCamerasServiceUrl("users"), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                params: params as any,
                observe: "response"
            })
            .pipe(
                map((result) => {
                    let count = result.body.length;
                    let total = result.body.length;
                    try {
                        count = parseInt(result.headers.get("X-Pagination-Count") ?? "0", 10);
                    } catch (e) {}
                    try {
                        total = parseInt(result.headers.get("X-Total-Count") ?? "0", 10);
                    } catch (e) {}

                    return {
                        list: plainToInstance(User, result.body as any[]),
                        count: count,
                        total: total
                    };
                })
            );
    }

    public getImeiInformations(imei: string): Observable<CameraImeiInformationsDTO> {
        return this.http
            .get<object>(this.getCamerasServiceUrl("imeis"), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                params: { imei: imei } as any,
                observe: "response"
            })
            .pipe(
                map((result) => {
                    return plainToInstance(CameraImeiInformationsDTO, result.body);
                })
            );
    }

    // TODO: use own DTO here instead of "Camera"
    public getUserCameras(userId: string): Observable<Camera> {
        return this.http
            .get<object>(this.getCamerasServiceUrl(`usercameras/${userId}`), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                observe: "response"
            })
            .pipe(
                map((result) => {
                    return plainToInstance(Camera, result.body);
                })
            );
    }

    private getCamerasServiceUrl(postfix: string): string {
        return this.cameraConfig.camerasAPI + postfix;
    }
}
