import { Component, OnInit, Host, ViewChild, TemplateRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Papa } from "ngx-papaparse";
import { ImeisService } from "./imeis.service";
import { AlertService, BaseDatatableComponent, ConfirmDialogComponent, DataTableParameters } from "@impacgroup/angular-baselib";
import { Imei } from "../../api-models/Imei";
import { ImeisMainComponent } from "./imeis.main.component";
import { AvailableProductTypes, ProductType, ProductTypeKey } from "@impacgroup/doerr-wildkamera-api-shared";

@Component({
    selector: "app-imeis",
    templateUrl: "./imeis.component.html",
    styleUrls: ["imeis.component.scss"]
})
export class ImeisComponent extends BaseDatatableComponent implements OnInit {
    public UTCDATEFORMAT: string = "";

    rows: Imei[] = [];

    @ViewChild("bulkUploadModalTemplate")
    public bulkUploadModalTemplate?: TemplateRef<any>;
    public bulkUploadModalRef?: BsModalRef;

    public productTypes: ProductType[] = AvailableProductTypes;
    public selectedProductTypeKey: ProductTypeKey = "cloud_4g_v1";

    constructor(
        private imeisService: ImeisService,
        private translateService: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: BsModalService,
        private alertService: AlertService,
        parent: ImeisMainComponent,
        private papa: Papa
    ) {
        super(translateService, {
            stateSaveMode: imeisService.datatableStateSaveMode,
            stateSaveComponent: parent,
            stateSaveStorageKey: "dtstate_imeis_list"
        });

        this.dtOptions.order = [[3, "desc"]];
        this.UTCDATEFORMAT = this.imeisService.UTCDATEFORMAT;
    }

    ngOnInit() {}

    _dataTableTrigger() {
        return (dataTableParameters: DataTableParameters, callback: Function) => {
            // get api query parameters by datatable
            const queryParams = this.getApiQueryParamsByDataTable(dataTableParameters);

            this.subscriptions.push(
                this.imeisService.imeis(queryParams).subscribe((result) => {
                    this.rows = result.list;

                    callback({
                        recordsTotal: result.total,
                        recordsFiltered: result.count,
                        data: []
                    });
                })
            );
        };
    }

    _dataTableColums() {
        return [
            { data: "realtimeStatus", orderable: false },
            { data: "code", orderable: true },
            { data: "productType", orderable: true },
            { data: "created", orderable: true },
            { data: "updated", orderable: true },
            { data: "actions", orderable: false }
        ];
    }

    openBulkImportModal() {
        if (this.bulkUploadModalTemplate) {
            this.bulkUploadModalRef = this.modalService.show(this.bulkUploadModalTemplate);
        }
    }

    addImei() {
        this.router.navigate(["add"], { relativeTo: this.route });
    }

    editImei(id?: string) {
        this.router.navigate([id], { relativeTo: this.route });
    }

    deleteImei(id?: string) {
        const initialState = {
            title: this.translateService.instant("imeis.list.delete.title"),
            message: this.translateService.instant("imeis.list.delete.message"),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok")
        };
        const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
        if (bsModalRef.content) {
            bsModalRef.content.confirmCallback = () => {
                if (!id) {
                    return;
                }

                this.subscriptions.push(
                    this.imeisService.deleteimei(id).subscribe((result) => {
                        this.alertService.addSuccess(this.translateService.instant("imeis.list.delete.success"));
                        this.rerenderDatatable();
                    })
                );
            };
        }
    }

    handleFileInput(ev: Event) {
        const eventTarget = ev.target as HTMLInputElement | null;
        const fileToUpload = eventTarget?.files?.item(0) || undefined;

        if (!fileToUpload) {
            return;
        }

        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            if (!fileReader.result) {
                return;
            }

            this.papa.parse(fileReader.result.toString(), {
                delimiter: (input?: string) => {
                    if ((input?.indexOf(";") ?? -1) > -1) {
                        return ";";
                    }
                    return ",";
                },
                complete: (result) => {
                    if (result && result.data && result.data.length > 0) {
                        const postRequest: Object[] = result.data
                            .filter((row: string[]) => row[0] && row[0].trim())
                            .map((row: string[]) => {
                                const obj: Object = { code: row[0], productType: this.selectedProductTypeKey };
                                return obj;
                            });

                        this.subscriptions.push(
                            this.imeisService.bulkCreate(postRequest).subscribe({
                                next: () => {
                                    this.alertService.addSuccess(this.translateService.instant("imeis.list.bulkimport.success"));
                                    this.rerenderDatatable();
                                    this.bulkUploadModalRef?.hide();
                                },
                                error: () => {
                                    this.bulkUploadModalRef?.hide();
                                }
                            })
                        );
                    } else {
                        this.alertService.addCustomErrorByI18nId(this.translateService.instant("imeis.list.bulkimport.nodata"));
                        this.bulkUploadModalRef?.hide();
                    }
                },
                error: () => {
                    this.alertService.addCustomErrorByI18nId(this.translateService.instant("imeis.list.bulkimport.invalidcsv"));
                    this.bulkUploadModalRef?.hide();
                }
            });
        };
        fileReader.readAsText(fileToUpload);
    }
}
