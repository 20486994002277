import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, BaseComponent, BaseComponentMode, ConfirmDialogComponent } from "@impacgroup/angular-baselib";
// import ctTooltip from "@impacgroup/chartist-plugin-tooltip";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { ICameraCorruptPicture } from "../../api-models/ICameraCorruptPicture";
import { Configuration } from "ng-chartist";
import { Cameras4GProService } from "./cameras4gpro.service";
import { CameraDetail4GPro } from "./cameradetail4gpro.viewmodel";
import { ProCameraCommandKey, ProductTypeKey } from "@impacgroup/doerr-wildkamera-api-shared";
import { EnumHelper } from "src/app/global/helpers/EnumHelper";
import { BsModalService } from "ngx-bootstrap/modal";
import { Cameras4GProRepository } from "./cameras4gpro.repository";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-cameradetail-4g-pro",
    templateUrl: "./cameradetail4gpro.component.html",
    styleUrls: ["cameradetail4gpro.component.scss"]
})
export class Cameradetail4GProComponent extends BaseComponent implements OnInit {
    private cameraId?: string;
    private productType?: ProductTypeKey;
    public grafanaMetricsUrl?: string;
    public camera?: CameraDetail4GPro;

    public UTCDATEFORMAT: string = "";

    // corrupt pictures statistics
    public start: Date = new Date();
    public end: Date = new Date();
    public corruptPictures: ICameraCorruptPicture[] = [];
    public chartistConfig: Configuration = {
        type: "Line",
        data: {
            labels: [],
            series: [
                {
                    name: "",
                    data: this.corruptPictures
                }
            ]
        },
        options: {
            height: "250px",
            fullWidth: true,
            low: 0,
            showArea: true,
            showPoint: true
            // plugins: [
            //     ctTooltip({
            //         valueTransform: function (value) {
            //             return value;
            //         }
            //     })
            // ]
        }
    };

    constructor(
        private route: ActivatedRoute,
        private cameras4GProRepository: Cameras4GProRepository,
        private cameras4GProService: Cameras4GProService,
        private router: Router,
        private alertService: AlertService,
        private translate: TranslateService,
        private _location: Location,
        private modalService: BsModalService
    ) {
        super();
        this.UTCDATEFORMAT = this.cameras4GProRepository.UTCDATEFORMAT;
        this.start = moment(this.start).subtract(14, "days").toDate();

        this.subscriptions.push(
            this.route.params.subscribe((params) => {
                this.cameraId = params["id"];
                this.productType = route.snapshot.data["productType"];
                if (this.productType && this.cameraId) {
                    this.initEditMode();
                } else {
                    this.back();
                }
            })
        );
    }

    ngOnInit(): void {}

    initEditMode() {
        this.MODE = BaseComponentMode.EDIT;

        if (!this.productType || !this.cameraId) {
            return;
        }

        const start = moment(moment(this.start).format("YYYY-MM-DD") + " 00:00:00").toDate();
        const end = moment(moment(this.end).format("YYYY-MM-DD") + " 23:59:59").toDate();

        this.subscriptions.push(
            this.cameras4GProService.read({ id: this.cameraId, start: start, end: end, productType: this.productType }).subscribe({
                next: (result) => {
                    this.camera = result;
                    this.corruptPictures = result?.corrupt?.pictures ?? [];
                    this.grafanaMetricsUrl = `${environment.grafanaMetricsUrl}${this.camera.imei}`;
                },
                error: (err) => {
                    this.router.navigate(["../../"], { relativeTo: this.route });
                }
            })
        );
    }

    public save() {
        if (!this.camera?._id || !this.productType) {
            return;
        }

        this.subscriptions.push(
            this.cameras4GProService.update(this.camera._id, this.camera, this.productType).subscribe(() => {
                this.alertService.addSuccess(this.translate.instant("cameras.detail.edit.success"));
                this.router.navigate(["../../"], { relativeTo: this.route });
            })
        );
    }

    public back() {
        this._location.back();
    }

    public loadStatisticData() {
        const start = moment(moment(this.start).format("YYYY-MM-DD") + " 00:00:00").toDate();
        const end = moment(moment(this.end).format("YYYY-MM-DD") + " 23:59:59").toDate();

        if (!this.productType || !this.cameraId) {
            return;
        }

        this.subscriptions.push(
            this.cameras4GProService.readCorruptPictureStatistics({ start, end, type: this.productType, id: this.cameraId, productType: this.productType }).subscribe((result) => {
                this.corruptPictures = result ?? [];
            })
        );
    }

    public initCamera() {
        this.confirmSendCommand(ProCameraCommandKey.init, true);
    }

    public takePicture() {
        this.confirmSendCommand(ProCameraCommandKey.take_picture, true);
    }

    public takeVideo() {
        this.confirmSendCommand(ProCameraCommandKey.take_video, true);
    }

    public restartCamera() {
        this.confirmSendCommand(ProCameraCommandKey.camera_restart, true);
    }

    public updateFirmware() {
        if (this.camera?.batteryLow) {
            const initialState = {
                title: this.translate.instant("cameras4gpro.detail.command.firmware_update.dialog.updateBatteryTitle"),
                message: this.translate.instant("cameras4gpro.detail.command.firmware_update.dialog.updateBatteryMsg"),
                confirmBtnName: this.translate.instant("global.buttons.ok"),
                closeBtnName: this.translate.instant("global.buttons.cancel")
            };
            const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
            if (bsModalRef.content) {
                bsModalRef.content.confirmCallback = () => {
                    // Do not ask twice
                    this.confirmSendCommand(ProCameraCommandKey.firmware_update, false);
                };
            }
        } else {
            this.confirmSendCommand(ProCameraCommandKey.firmware_update, true);
        }
    }

    public formatSDCard() {
        this.confirmSendCommand(ProCameraCommandKey.format_sdcard, true);
    }

    public cameraTurnOn() {
        this.confirmSendCommand(ProCameraCommandKey.camera_turn_on, true);
    }

    public cameraTurnOff() {
        this.confirmSendCommand(ProCameraCommandKey.camera_shutdown, true);
    }

    public cameraReset() {
        this.confirmSendCommand(ProCameraCommandKey.camera_reset, true);
    }

    public sendStatus() {
        this.confirmSendCommand(ProCameraCommandKey.send_status, true);
    }

    public syncSettings() {
        this.confirmSendCommand(ProCameraCommandKey.sync_settings, true);
    }

    public openMetrics() {
        if (this.grafanaMetricsUrl) {
            window.open(this.grafanaMetricsUrl.toString(), "_blank");
        }
    }

    private confirmSendCommand(command: ProCameraCommandKey, showConfirmationDialog = false) {
        if (!this.camera?._id) {
            return;
        }

        if (showConfirmationDialog) {
            const commandKey = EnumHelper.getEnumKeyByValue(ProCameraCommandKey, command);

            const initialState = {
                title: this.translate.instant(`cameras4gpro.detail.command.${commandKey}.dialog.confirmationTitle`),
                message: this.translate.instant(`cameras4gpro.detail.command.${commandKey}.dialog.confirmationMsg`),
                confirmBtnName: this.translate.instant("global.buttons.ok"),
                closeBtnName: this.translate.instant("global.buttons.cancel")
            };
            const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
            if (bsModalRef.content) {
                bsModalRef.content.confirmCallback = () => {
                    this.sendCommand(command);
                };
            }
        } else {
            this.sendCommand(command);
        }
    }

    private sendCommand(command: ProCameraCommandKey) {
        if (!this.camera?._id || !this.productType) {
            return;
        }

        const commandKey = EnumHelper.getEnumKeyByValue(ProCameraCommandKey, command);

        const sendCmdSub = this.cameras4GProService.sendCommand(this.camera._id, command, this.productType).subscribe({
            next: () => {
                this.alertService.addSuccess(this.translate.instant(`cameras4gpro.detail.command.${commandKey}.alert.success`));
            },
            error: (err) => {
                switch (err.error.code) {
                    case "camera_command_offline":
                        this.alertService.addCustomError(this.translate.instant("cameras4gpro.detail.alert.cameraCommandOffline"));
                        break;
                }
            }
        });
        this.subscriptions.push(sendCmdSub);
    }
}
