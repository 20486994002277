<main class="main" [attr.data-ma-theme]="service.maTheme">
    <app-header></app-header>
    <app-sidebar [class.toggled]="service.mobileSidebarActive"></app-sidebar>
    <section class="content">
        <div class="alert_message_container" *ngIf="alertService.alerts.length > 0">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-6 offset-md-3 animated" [ngClass]="alert.animationClass" *ngFor="let alert of alertService.alerts">
                        <alert [type]="alert.type" [dismissible]="true" (onClosed)="alertService.onAlertClosed(alert)"><span [innerHtml]="alert.msg"></span></alert>
                    </div>
                </div>
            </div>
        </div>

        <router-outlet></router-outlet>
        <app-footer></app-footer>
        <div *ngIf="service.mobileSidebarActive" (click)="service.mobileSidebarActive = false" class="sidebar-backdrop"></div>
    </section>

    <app-page-loader></app-page-loader>
</main>
