import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorageAdapter } from "@impacgroup/angular-oauth-base";
import { ApiListQueryParameter, BaseDatatableStateSaveMode } from "@impacgroup/angular-baselib";
import { map } from "rxjs/operators";
import { plainToInstance } from "class-transformer";
import { Firmware } from "../../api-models/Firmware";

export interface IFirmwareConfigService {
    firmwaresAPI: string;
    utcDateFormat: string;
    datatableStateSaveMode: BaseDatatableStateSaveMode;
}

export const FirmwareConfigService = new InjectionToken<IFirmwareConfigService>("FirmwareConfig");

@Injectable()
export class FirmwaresService {
    public UTCDATEFORMAT: string = "";
    public datatableStateSaveMode: BaseDatatableStateSaveMode;

    constructor(@Inject(FirmwareConfigService) private firmwareConfig: IFirmwareConfigService, private http: HttpClient, private oauthStorageAdapter: OAuthStorageAdapter) {
        this.UTCDATEFORMAT = this.firmwareConfig.utcDateFormat;
        this.datatableStateSaveMode = this.firmwareConfig.datatableStateSaveMode;
    }

    public firmwares(params: ApiListQueryParameter): Observable<{ list: Firmware[]; count: number; total: number }> {
        return this.http
            .get<any>(this.getFirmwaresServiceUrl(""), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                params: params as any,
                observe: "response"
            })
            .pipe(
                map((result) => {
                    let count = result.body.length;
                    let total = result.body.length;
                    try {
                        count = parseInt(result.headers.get("X-Pagination-Count") ?? "0", 10);
                    } catch (e) {}
                    try {
                        total = parseInt(result.headers.get("X-Total-Count") ?? "0", 10);
                    } catch (e) {}

                    return {
                        list: plainToInstance(Firmware, result.body as any[]),
                        count: count,
                        total: total
                    };
                })
            );
    }

    public firmwareDetail(id: string): Observable<Firmware> {
        return this.http
            .get<Object>(this.getFirmwaresServiceUrl(id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToInstance(Firmware, result)));
    }

    public addFirmware(firmware: Firmware, file?: File): Observable<Firmware> {
        const formData: FormData = new FormData();
        formData.append("data", JSON.stringify(firmware));

        if (file) {
            formData.append("file", file, file.name);
        }

        return this.http
            .post<Object>(this.getFirmwaresServiceUrl(""), formData, {
                headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType()
            })
            .pipe(map((result) => plainToInstance(Firmware, result)));
    }

    public updateFirmware(firmware: Firmware, file?: File): Observable<Firmware> {
        const formData: FormData = new FormData();
        formData.append("data", JSON.stringify(firmware));

        if (file) {
            formData.append("file", file, file.name);
        }

        if (!firmware._id) {
            throw new Error("Cannot update object without _id");
        }

        return this.http
            .patch<Object>(this.getFirmwaresServiceUrl(firmware._id), formData, {
                headers: this.oauthStorageAdapter.getAuthHeadersWithoutContentType()
            })
            .pipe(map((result) => plainToInstance(Firmware, result)));
    }

    public deleteFirmware(id: string): Observable<Firmware> {
        return this.http
            .delete<Object>(this.getFirmwaresServiceUrl(id), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON()
            })
            .pipe(map((result) => plainToInstance(Firmware, result)));
    }

    public getCustomCSVData(): Observable<string | null> {
        return this.http
            .get<Object>(this.getFirmwaresServiceUrl("apns"), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                observe: "response",
                responseType: "json"
            })
            .pipe(map((response) => (response.body && "customCSV" in response.body && response.body.customCSV && typeof response.body.customCSV === "string" ? response.body.customCSV : null)));
    }

    public updateCustomCSVData(customCSVData: string): Observable<string | null> {
        return this.http
            .post<Blob>(
                this.getFirmwaresServiceUrl("apns"),
                { customCSVData },
                {
                    headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                    observe: "response",
                    responseType: "json"
                }
            )
            .pipe(map((response) => (response.body && "customCSV" in response.body && response.body.customCSV && typeof response.body.customCSV === "string" ? response.body.customCSV : null)));
    }

    public downloadAPNs(): Observable<Blob | null> {
        return this.http
            .get<Blob>(this.getFirmwaresServiceUrl("apns/csvFile"), {
                headers: this.oauthStorageAdapter.getAuthHeadersJSON(),
                observe: "response",
                responseType: "blob" as "json"
            })
            .pipe(map((response) => (response.body ? response.body : null)));
    }

    private getFirmwaresServiceUrl(postfix: string): string {
        return this.firmwareConfig.firmwaresAPI + postfix;
    }
}
