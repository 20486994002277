import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, BaseComponent, BaseComponentMode } from "@impacgroup/angular-baselib";
import { StolenCamerasService } from "./stolencameras.service";
import { TranslateService } from "@ngx-translate/core";
import { StolenCamera } from "../../api-models/StolenCamera";
import { StandardCountryService } from "../../global/services/country.service";

@Component({
    selector: "app-stolencameradetail",
    templateUrl: "./stolencameradetail.component.html",
    styleUrls: ["stolencameradetail.component.scss"]
})
export class StolenCameraDetailComponent extends BaseComponent implements OnInit {
    private cameraIdToAdd?: string; // if in add mode, we need the camera id!
    private stolenCameraId?: string;

    public stolenCamera?: StolenCamera;
    public countries: { code: string; name: string }[] = [];

    constructor(
        private route: ActivatedRoute,
        private stolenCamerasService: StolenCamerasService,
        private router: Router,
        private alertService: AlertService,
        private translateService: TranslateService,
        private _location: Location,
        private countryService: StandardCountryService
    ) {
        super();

        this.subscriptions.push(
            this.countryService.getAllCountries().subscribe((result) => {
                this.countries = result;
            })
        );

        this.subscriptions.push(
            this.route.params.subscribe((params) => {
                this.stolenCameraId = params["id"];
                this.cameraIdToAdd = params["cameraId"];
                if (this.stolenCameraId) {
                    this.initEditMode();
                    return;
                }
                if (this.cameraIdToAdd) {
                    this.initAddMode();
                    return;
                }
                this.router.navigate(["../"], { relativeTo: this.route }); // <= if not in edit or add mode => we leave this page
            })
        );
    }

    ngOnInit(): void {}

    initAddMode() {
        this.MODE = BaseComponentMode.ADD;
        this.stolenCamera = new StolenCamera();
    }

    initEditMode() {
        this.MODE = BaseComponentMode.EDIT;

        if (!this.stolenCameraId) {
            return;
        }

        this.subscriptions.push(
            this.stolenCamerasService.stolenCameraDetail(this.stolenCameraId).subscribe({
                next: (result) => {
                    this.stolenCamera = result;
                },
                error: (err) => {
                    this.router.navigate(["../"], { relativeTo: this.route });
                }
            })
        );
    }

    save() {
        if (!this.stolenCamera) {
            return;
        }

        if (this.MODE === BaseComponentMode.ADD) {
            this.stolenCamera.camera = this.cameraIdToAdd;
            this.subscriptions.push(
                this.stolenCamerasService.addStolenCamera(this.stolenCamera).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("stolencameras.detail.add.success"));
                    this.router.navigate(["../../"], { relativeTo: this.route });
                })
            );
        }

        if (this.MODE === BaseComponentMode.EDIT) {
            this.subscriptions.push(
                this.stolenCamerasService.updateStolenCamera(this.stolenCamera).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("stolencameras.detail.edit.success"));
                    this.router.navigate(["../"], { relativeTo: this.route });
                })
            );
        }
    }

    back() {
        this._location.back();
    }
}
