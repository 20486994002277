<ng-template #i18nTemplate let-i18nKey="i18nKey" let-item="item">{{ i18nKey + "." + item | translate }}</ng-template>

<form name="form" class="row" *ngIf="camera && camera.proSettings" (ngSubmit)="f.form.valid && save()" #f="ngForm" novalidate>
    <div class="col-md-12">
        <tabset>
            <tab [heading]="'cameras.detail.edit.tabs.basedata' | translate">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "cameras.props.name" | translate }}</label>
                            <input name="name" type="text" class="form-control" [(ngModel)]="camera.name" [disabled]="!mayEditBasedata" [ngClass]="{ 'is-invalid': f.submitted && f.controls['name'].invalid }" required />
                            <i class="form-group__bar"></i>
                            <div class="invalid-tooltip">
                                {{ "cameras.detail.validation.name" | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </tab>

            <tab [heading]="'cameras.detail.edit.tabs.settings' | translate">
                <div class="row">
                    <!-- Camera settings -->
                    <h5 class="col-12">{{ "camera4gprosettings.camera" | translate }}</h5>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "camera4gprosettings.cameraMode.title" | translate }}</label>
                            <ng-select name="cameraMode" [items]="cameraModeOptions" placeholder="" [clearable]="false" [(ngModel)]="camera.proSettings.cameraMode" required>
                                <ng-template ng-label-tmp ng-option-tmp let-item="item"><ng-container *ngTemplateOutlet="i18nTemplate; context: { item, i18nKey: 'camera4gprosettings.cameraMode.options' }"> </ng-container></ng-template>
                            </ng-select>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "camera4gprosettings.sendingMode.title" | translate }}</label>
                            <ng-select name="sendingMode" [items]="sendingModeOptions" placeholder="" [clearable]="false" [(ngModel)]="camera.proSettings.sendingMode" required>
                                <ng-template ng-label-tmp ng-option-tmp let-item="item"><ng-container *ngTemplateOutlet="i18nTemplate; context: { item, i18nKey: 'camera4gprosettings.sendingMode.options' }"> </ng-container></ng-template>
                            </ng-select>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "camera4gprosettings.securityMode.title" | translate }}</label>
                            <ng-select name="securityMode" [items]="securityModeOptions" placeholder="" [clearable]="false" [(ngModel)]="camera.proSettings.securityMode" required>
                                <ng-template ng-label-tmp ng-option-tmp let-item="item"><ng-container *ngTemplateOutlet="i18nTemplate; context: { item, i18nKey: 'camera4gprosettings.securityMode.options' }"> </ng-container></ng-template>
                            </ng-select>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "camera4gprosettings.batteryType.title" | translate }}</label>
                            <ng-select name="batteryType" [items]="batteryTypeOptions" placeholder="" [clearable]="false" [(ngModel)]="camera.proSettings.batteryType" required>
                                <ng-template ng-label-tmp ng-option-tmp let-item="item"><ng-container *ngTemplateOutlet="i18nTemplate; context: { item, i18nKey: 'camera4gprosettings.batteryType.options' }"> </ng-container></ng-template>
                            </ng-select>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "camera4gprosettings.remoteControl.title" | translate }}</label>
                            <ng-select name="remoteControl" [items]="remoteControlOptions" placeholder="" [clearable]="false" [(ngModel)]="camera.proSettings.remoteControl" required>
                                <ng-template ng-label-tmp ng-option-tmp let-item="item"><ng-container *ngTemplateOutlet="i18nTemplate; context: { item, i18nKey: 'camera4gprosettings.remoteControl.options' }"> </ng-container></ng-template>
                            </ng-select>
                        </div>
                    </div>

                    <div class="col-6 col-md-3">
                        <div class="form-group">
                            <label>{{ "camera4gprosettings.gpsMode" | translate }}</label>
                            <div class="row">
                                <div class="col-12">
                                    <div class="toggle-switch">
                                        <input type="checkbox" class="toggle-switch__checkbox" [(ngModel)]="camera.proSettings.gpsMode" id="gpsMode" name="gpsMode" />
                                        <i class="toggle-switch__helper"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-6 col-md-3">
                        <div class="form-group">
                            <label>{{ "camera4gprosettings.sdLoop" | translate }}</label>
                            <div class="row">
                                <div class="col-12">
                                    <div class="toggle-switch">
                                        <input type="checkbox" class="toggle-switch__checkbox" [(ngModel)]="camera.proSettings.sdLoop" id="sdLoop" name="sdLoop" />
                                        <i class="toggle-switch__helper"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "camera4gprosettings.timezone.title" | translate }}</label>
                            <ng-select name="timezoneIdentifier" [items]="timezoneIdOptions" placeholder="" [clearable]="false" [(ngModel)]="camera.proSettings.timezoneIdentifier" required> </ng-select>
                        </div>
                    </div>

                    <div class="col-6 col-md-3" *ngIf="camera.showAutoscaleSetting">
                        <div class="form-group">
                            <label>{{ "camera4gprosettings.autoscaleMode" | translate }}</label>
                            <div class="row">
                                <div class="col-12">
                                    <div class="toggle-switch">
                                        <input type="checkbox" class="toggle-switch__checkbox" [(ngModel)]="camera.proSettings.autoscaleMode" id="autoscaleMode" name="autoscaleMode" />
                                        <i class="toggle-switch__helper"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Picture settings -->
                    <h5 class="col-12">{{ "camera4gprosettings.picture" | translate }}</h5>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "camera4gprosettings.imageSize.title" | translate }}</label>
                            <ng-select name="imageSize" [items]="imageSizeOptions" placeholder="" [clearable]="false" [(ngModel)]="camera.proSettings.imageSize" required>
                                <ng-template ng-label-tmp ng-option-tmp let-item="item"><ng-container *ngTemplateOutlet="i18nTemplate; context: { item, i18nKey: 'camera4gprosettings.imageSize.options' }"> </ng-container></ng-template>
                            </ng-select>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "camera4gprosettings.multiShot.title" | translate }}</label>
                            <ng-select name="multiShot" [items]="multiShotOptions" placeholder="" [clearable]="false" [(ngModel)]="camera.proSettings.multiShot" required>
                                <ng-template ng-label-tmp ng-option-tmp let-item="item"><ng-container *ngTemplateOutlet="i18nTemplate; context: { item, i18nKey: 'camera4gprosettings.multiShot.options' }"> </ng-container></ng-template>
                            </ng-select>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "camera4gprosettings.multiShotInterval.title" | translate }}</label>
                            <ng-select name="multiShotInterval" [items]="multiShotIntervalOptions" placeholder="" [clearable]="false" [(ngModel)]="camera.proSettings.multiShotInterval" required>
                                <ng-template ng-label-tmp ng-option-tmp let-item="item"
                                    ><ng-container *ngTemplateOutlet="i18nTemplate; context: { item, i18nKey: 'camera4gprosettings.multiShotInterval.options' }"> </ng-container
                                ></ng-template>
                            </ng-select>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "camera4gprosettings.timelapse.title" | translate }}</label>
                            <div class="row">
                                <div class="col-8" ngModelGroup="timeLapse" [ngClass]="{ 'is-invalid': f.submitted && camera.proSettings.timeLapseActive && f.controls['timeLapse'].invalid }">
                                    <input
                                        [(ngModel)]="camera.proSettings.timeLapseHH"
                                        name="timeLapseHH"
                                        class="form-control timeInput"
                                        #timeLapseHH="ngModel"
                                        [ngClass]="{ 'is-invalid': timeLapseHH.invalid }"
                                        (change)="updateTimeLapseSSPattern()"
                                        maxlength="2"
                                        pattern="[01][0-9]|2[0-3]|^[0-9]$"
                                        placeholder="HH"
                                        type="tel"
                                        [readonly]="!camera.proSettings.timeLapseActive"
                                        [required]="!!camera.proSettings.timeLapseActive"
                                    />
                                    <span class="float-left timeDivider">:</span>
                                    <input
                                        [(ngModel)]="camera.proSettings.timeLapseMM"
                                        name="timeLapseMM"
                                        class="form-control timeInput"
                                        #timeLapseMM="ngModel"
                                        [ngClass]="{ 'is-invalid': timeLapseMM.invalid }"
                                        (change)="updateTimeLapseSSPattern()"
                                        maxlength="2"
                                        pattern="[0-5][0-9]|^[0-9]$"
                                        placeholder="MM"
                                        type="tel"
                                        [readonly]="!camera.proSettings.timeLapseActive"
                                        [required]="!!camera.proSettings.timeLapseActive"
                                    />
                                    <span class="float-left timeDivider">:</span>
                                    <input
                                        [(ngModel)]="camera.proSettings.timeLapseSS"
                                        name="timeLapseSS"
                                        class="form-control timeInput"
                                        #timeLapseSS="ngModel"
                                        [ngClass]="{ 'is-invalid': timeLapseSS.invalid }"
                                        maxlength="2"
                                        [pattern]="timeLapseSSPattern"
                                        placeholder="SS"
                                        type="tel"
                                        [readonly]="!camera.proSettings.timeLapseActive"
                                        [required]="!!camera.proSettings.timeLapseActive"
                                    />
                                </div>

                                <div class="invalid-tooltip">
                                    {{ "camera4gprosettings.timelapse.validation.invalid" | translate }}
                                </div>

                                <div class="col-4">
                                    <div class="toggle-switch">
                                        <input type="checkbox" class="toggle-switch__checkbox" [(ngModel)]="camera.proSettings.timeLapseActive" id="timeLapseActivated" name="timeLapseActivated" (change)="modelChanged()" />
                                        <i class="toggle-switch__helper"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "camera4gprosettings.delay.title" | translate }}</label>
                            <div class="row">
                                <div class="col-8" ngModelGroup="delay" [ngClass]="{ 'is-invalid': f.submitted && camera.proSettings.delayActive && f.controls['delay'].invalid }">
                                    <input
                                        [(ngModel)]="camera.proSettings.delayHH"
                                        name="delayHH"
                                        class="form-control timeInput"
                                        #delayHH="ngModel"
                                        [ngClass]="{ 'is-invalid': delayHH.invalid }"
                                        (change)="updateDelaySSPattern()"
                                        maxlength="2"
                                        pattern="[01][0-9]|2[0-3]|^[0-9]$"
                                        placeholder="HH"
                                        type="tel"
                                        [readonly]="!camera.proSettings.delayActive"
                                        [required]="!!camera.proSettings.delayActive"
                                    />
                                    <span class="float-left timeDivider">:</span>
                                    <input
                                        [(ngModel)]="camera.proSettings.delayMM"
                                        name="delayMM"
                                        class="form-control timeInput"
                                        #delayMM="ngModel"
                                        [ngClass]="{ 'is-invalid': delayMM.invalid }"
                                        (change)="updateDelaySSPattern()"
                                        maxlength="2"
                                        pattern="[0-5][0-9]|^[0-9]$"
                                        placeholder="MM"
                                        type="tel"
                                        [readonly]="!camera.proSettings.delayActive"
                                        [required]="!!camera.proSettings.delayActive"
                                    />
                                    <span class="float-left timeDivider">:</span>
                                    <input
                                        [(ngModel)]="camera.proSettings.delaySS"
                                        name="delaySS"
                                        class="form-control timeInput"
                                        #delaySS="ngModel"
                                        [ngClass]="{ 'is-invalid': delaySS.invalid }"
                                        maxlength="2"
                                        [pattern]="delaySSPattern"
                                        placeholder="SS"
                                        type="tel"
                                        [readonly]="!camera.proSettings.delayActive"
                                        [required]="!!camera.proSettings.delayActive"
                                    />
                                </div>

                                <div class="invalid-tooltip">
                                    {{ "camera4gprosettings.delay.validation.invalid" | translate }}
                                </div>

                                <div class="col-4">
                                    <div class="toggle-switch">
                                        <input type="checkbox" class="toggle-switch__checkbox" [(ngModel)]="camera.proSettings.delayActive" id="delayActivated" name="delayActivated" (change)="modelChanged()" />
                                        <i class="toggle-switch__helper"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "camera4gprosettings.maxNumPerDay.title" | translate }}</label>
                            <div class="row">
                                <div class="col-8">
                                    <div class="form-group">
                                        <input
                                            name="maxNumPerDay"
                                            maxlength="2"
                                            type="number"
                                            class="form-control"
                                            [(ngModel)]="camera.proSettings.maxNumPerDay"
                                            [ngClass]="{ 'is-invalid': f.submitted && f.controls['maxNumPerDay'].invalid }"
                                            [disabled]="!camera.proSettings.maxNumPerDayActive"
                                            [min]="maxNumPerDayMinValue"
                                            [max]="maxNumPerDayMaxValue"
                                            [required]="!!camera.proSettings.maxNumPerDayActive"
                                        />
                                        <i class="form-group__bar"></i>
                                        <div class="invalid-tooltip">
                                            {{ "camera4gprosettings.maxNumPerDay.validation.invalid" | translate : { maxNumPerDayMinValue, maxNumPerDayMaxValue } }}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-4">
                                    <div class="toggle-switch">
                                        <input type="checkbox" class="toggle-switch__checkbox" [(ngModel)]="camera.proSettings.maxNumPerDayActive" id="maxNumPerDayActive" name="maxNumPerDayActive" (change)="modelChanged()" />
                                        <i class="toggle-switch__helper"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Video settings -->
                    <h5 class="col-12">{{ "camera4gprosettings.videoSettings" | translate }}</h5>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "camera4gprosettings.videoSize.title" | translate }}</label>
                            <ng-select name="videoSize" [items]="videoSizeOptions" placeholder="" [clearable]="false" [(ngModel)]="camera.proSettings.videoSize" required>
                                <ng-template ng-label-tmp ng-option-tmp let-item="item"><ng-container *ngTemplateOutlet="i18nTemplate; context: { item, i18nKey: 'camera4gprosettings.videoSize.options' }"> </ng-container></ng-template>
                            </ng-select>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "camera4gprosettings.videoLength.title" | translate }}</label>
                            <input
                                name="videoLength"
                                type="number"
                                class="form-control"
                                [(ngModel)]="camera.proSettings.videoLength"
                                [ngClass]="{ 'is-invalid': f.submitted && f.controls['videoLength'].invalid }"
                                [min]="videoLengthMinValue"
                                [max]="videoLengthMaxValue"
                                required
                            />
                            <i class="form-group__bar"></i>
                            <div class="invalid-tooltip">
                                {{ "cameras.detail.validation.settings.videoLength" | translate : { videoLengthMinValue, videoLengthMaxValue } }}
                            </div>
                        </div>
                    </div>

                    <!-- Light settings -->
                    <h5 class="col-12">{{ "camera4gprosettings.light" | translate }}</h5>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "camera4gprosettings.flashpower.title" | translate }}</label>
                            <ng-select name="flashpower" [items]="flashPowerOptions" placeholder="" [clearable]="false" [(ngModel)]="camera.proSettings.flashPower" required>
                                <ng-template ng-label-tmp ng-option-tmp let-item="item"><ng-container *ngTemplateOutlet="i18nTemplate; context: { item, i18nKey: 'camera4gprosettings.flashpower.options' }"> </ng-container></ng-template>
                            </ng-select>
                        </div>
                    </div>

                    <!-- PIR settings -->
                    <h5 class="col-12">{{ "camera4gprosettings.movementTracking" | translate }}</h5>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "camera4gprosettings.pirSensitivity.title" | translate }}</label>

                            <ng-select name="pirSensitivity" [items]="pirArray" placeholder="" [clearable]="false" [(ngModel)]="camera.proSettings.pirSensitivity" required> </ng-select>
                            <div class="invalid-tooltip">
                                {{ "camera4gprosettings.pirSensitivity.validation.invalid" | translate }}
                            </div>
                        </div>
                    </div>

                    <!-- Timer settings -->
                    <h5 class="col-12">{{ "camera4gprosettings.timeschedule" | translate }}</h5>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "camera4gprosettings.workTimer1.title" | translate }}</label>
                            <div class="row">
                                <div class="col-8" ngModelGroup="workTimer1" [ngClass]="{ 'is-invalid': f.submitted && camera.proSettings.workTimer1Active && f.controls['workTimer1'].invalid }">
                                    <timepicker
                                        id="workTimer1From"
                                        name="workTimer1From"
                                        [(ngModel)]="camera.proSettings.workTimer1From"
                                        [showMeridian]="false"
                                        [showSpinners]="false"
                                        [minuteStep]="1"
                                        [readonlyInput]="!camera.proSettings.workTimer1Active"
                                        [required]="!!camera.proSettings.workTimer1Active"
                                    ></timepicker>
                                    <timepicker
                                        id="workTimer1To"
                                        name="workTimer1To"
                                        [(ngModel)]="camera.proSettings.workTimer1To"
                                        [showMeridian]="false"
                                        [showSpinners]="false"
                                        [minuteStep]="1"
                                        [readonlyInput]="!camera.proSettings.workTimer1Active"
                                        [required]="!!camera.proSettings.workTimer1Active"
                                    ></timepicker>
                                    <i class="form-group__bar"></i>
                                </div>

                                <div class="invalid-tooltip">
                                    {{ "camera4gprosettings.workTimer1.validation.invalid" | translate }}
                                </div>

                                <div class="col-4">
                                    <div class="toggle-switch">
                                        <input type="checkbox" class="toggle-switch__checkbox" [(ngModel)]="camera.proSettings.workTimer1Active" id="workTimer1Activated" name="workTimer1Activated" (change)="modelChanged()" />
                                        <i class="toggle-switch__helper"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ "camera4gprosettings.workTimer2.title" | translate }}</label>
                            <div class="row">
                                <div class="col-8" ngModelGroup="workTimer2" [ngClass]="{ 'is-invalid': f.submitted && camera.proSettings.workTimer2Active && f.controls['workTimer2'].invalid }">
                                    <timepicker
                                        id="workTimer2"
                                        name="workTimer2"
                                        [(ngModel)]="camera.proSettings.workTimer2From"
                                        [showMeridian]="false"
                                        [showSpinners]="false"
                                        [minuteStep]="1"
                                        [readonlyInput]="!camera.proSettings.workTimer2Active"
                                        [required]="!!camera.proSettings.workTimer2Active"
                                    ></timepicker>
                                    <timepicker
                                        id="workTimer2end"
                                        name="workTimer2end"
                                        [(ngModel)]="camera.proSettings.workTimer2To"
                                        [showMeridian]="false"
                                        [showSpinners]="false"
                                        [minuteStep]="1"
                                        [readonlyInput]="!camera.proSettings.workTimer2Active"
                                        [required]="!!camera.proSettings.workTimer2Active"
                                    ></timepicker>
                                    <i class="form-group__bar"></i>
                                </div>

                                <div class="invalid-tooltip">
                                    {{ "camera4gprosettings.workTimer2.validation.invalid" | translate }}
                                </div>

                                <div class="col-4">
                                    <div class="toggle-switch">
                                        <input type="checkbox" class="toggle-switch__checkbox" [(ngModel)]="camera.proSettings.workTimer2Active" id="workTimer2Activated" name="workTimer2Activated" (change)="modelChanged()" />
                                        <i class="toggle-switch__helper"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </tab>
        </tabset>
    </div>

    <div class="col-md-12">
        <button *ngIf="showAddStolenCameraButton()" (click)="addStolenCamera()" class="btn btn-warning btn--icon-text"><i class="zmdi zmdi-collection-plus"></i> {{ "stolencameras.list.add" | translate }}</button>
        <button *ngIf="showEditStolenCameraButton()" (click)="editStolenCamera()" class="btn btn-warning btn--icon-text" style="margin-left: 25px"><i class="zmdi zmdi-edit"></i> {{ "stolencameras.list.edit" | translate }}</button>

        <button class="float-right btn btn-success btn--icon-text" [disabled]="f.submitted && f.invalid"><i class="zmdi zmdi-check"></i> {{ "global.buttons.save" | translate }}</button>
    </div>
</form>
