<div class="content__inner">
    <header class="content__title">
        <h1>{{ "firmwares.apns.title" | translate }}</h1>
        <small>{{ "firmwares.apns.subtitle" | translate }}</small>

        <div class="actions">
            <button (click)="back()" class="btn btn-secondary btn--icon-text"><i class="zmdi zmdi-arrow-back"></i> {{ "global.buttons.back" | translate }}</button>
        </div>
    </header>

    <div class="card" *appHasPermission="'APNS.LIST'">
        <div class="card-body">
            <div class="col-md-12">
                <div class="form-group">
                    <label>{{ "firmwares.apns.customcsv.title" | translate }}</label>
                    <div>
                        <small>{{ "firmwares.apns.customcsv.hintTitle" | translate }}</small
                        ><BR />
                        <small>{{ "firmwares.apns.customcsv.hintMsg" | translate }}</small>
                    </div>
                    <BR />
                    <div>
                        <small>{{ "firmwares.apns.customcsv.exampleTitle" | translate }}</small
                        ><BR />
                        <small>{{ "firmwares.apns.customcsv.exampleMsg" | translate }}</small>
                    </div>
                    <textarea name="name" rows="5" type="text" class="form-control" [(ngModel)]="vm.customCSVData" #name="ngModel" [ngClass]="{ 'is-invalid': vm.customCSVDataInvalid }"></textarea>
                    <i class="form-group__bar"></i>
                    <div class="invalid-tooltip">
                        {{ "firmwares.detail.validation.name" | translate }}
                    </div>
                </div>
            </div>

            <div class="col-md-12">
                <button class="float-right btn btn-success btn--icon-text" (click)="downloadApns()" *appHasPermission="'APNS.LIST'"><i class="zmdi zmdi-check"></i> {{ "firmwares.apns.download" | translate }}</button>
                <button class="float-right btn btn-success btn--icon-text me-2 mr-2" (click)="saveData()" *appHasPermission="'APNS.EDIT'"><i class="zmdi zmdi-check"></i> {{ "global.buttons.save" | translate }}</button>
            </div>
        </div>
    </div>
</div>
