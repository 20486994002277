import { Component, OnInit, Host } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AboUsersService } from "./abousers.service";
import { BaseDatatableComponent, DataTableParameters } from "@impacgroup/angular-baselib";
import { AboUser } from "../../api-models/AboUser";
import { AboUsersMainComponent } from "./abousers.main.component";

@Component({
    selector: "app-abousers",
    templateUrl: "./abousers.component.html",
    styleUrls: ["abousers.component.scss"]
})
export class AboUsersComponent extends BaseDatatableComponent implements OnInit {
    public UTCDATEFORMAT: string = "";

    rows: AboUser[] = [];

    constructor(private aboUsersService: AboUsersService, private translateService: TranslateService, private router: Router, private route: ActivatedRoute, parent: AboUsersMainComponent) {
        super(translateService, {
            stateSaveMode: aboUsersService.datatableStateSaveMode,
            stateSaveComponent: parent,
            stateSaveStorageKey: "dtstate_abousers_list"
        });

        this.dtOptions.order = [[3, "desc"]];
        this.UTCDATEFORMAT = this.aboUsersService.UTCDATEFORMAT;
    }

    ngOnInit() {}

    _dataTableTrigger() {
        return (dataTableParameters: DataTableParameters, callback: Function) => {
            // get api query parameters by datatable
            const queryParams = this.getApiQueryParamsByDataTable(dataTableParameters);

            this.subscriptions.push(
                this.aboUsersService.aboUsers(queryParams).subscribe((result) => {
                    this.rows = result.list;

                    callback({
                        recordsTotal: result.total,
                        recordsFiltered: result.count,
                        data: []
                    });
                })
            );
        };
    }

    _dataTableColums() {
        return [
            { data: "email", orderable: true },
            { data: "firstname", orderable: true },
            { data: "lastname", orderable: true },
            { data: "created", orderable: true },
            { data: "updated", orderable: true },
            { data: "actions", orderable: false }
        ];
    }

    viewAboUser(id?: string) {
        this.router.navigate([id], { relativeTo: this.route });
    }
}
