<div class="content__inner">
    <header class="content__title">
        <h1 *ngIf="MODE === 'ADD'">{{ "stolencameras.detail.add.title" | translate }}</h1>
        <h1 *ngIf="MODE === 'EDIT'">{{ "stolencameras.detail.edit.title" | translate }}</h1>

        <div class="actions">
            <button (click)="back()" class="btn btn-secondary btn--icon-text"><i class="zmdi zmdi-arrow-back"></i> {{ "global.buttons.back" | translate }}</button>
        </div>
    </header>

    <div class="card">
        <div class="card-body">
            <form name="form" class="row" *ngIf="stolenCamera" (ngSubmit)="f.form.valid && save()" #f="ngForm" novalidate>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "stolencameras.props.firstname" | translate }}</label>
                        <input name="firstname" type="text" class="form-control" [(ngModel)]="stolenCamera.firstname" #firstname="ngModel" [ngClass]="{ 'is-invalid': f.submitted && firstname.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ "stolencameras.detail.validation.firstname" | translate }}
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "stolencameras.props.lastname" | translate }}</label>
                        <input name="lastname" type="text" class="form-control" [(ngModel)]="stolenCamera.lastname" #lastname="ngModel" [ngClass]="{ 'is-invalid': f.submitted && lastname.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ "stolencameras.detail.validation.lastname" | translate }}
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "stolencameras.props.zip" | translate }}</label>
                        <input name="zip" type="text" class="form-control" [(ngModel)]="stolenCamera.zip" #zip="ngModel" [ngClass]="{ 'is-invalid': f.submitted && zip.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ "stolencameras.detail.validation.zip" | translate }}
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "stolencameras.props.city" | translate }}</label>
                        <input name="city" type="text" class="form-control" [(ngModel)]="stolenCamera.city" #city="ngModel" [ngClass]="{ 'is-invalid': f.submitted && city.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ "stolencameras.detail.validation.city" | translate }}
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "stolencameras.props.address" | translate }}</label>
                        <input name="address" type="text" class="form-control" [(ngModel)]="stolenCamera.address" #address="ngModel" [ngClass]="{ 'is-invalid': f.submitted && address.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ "stolencameras.detail.validation.address" | translate }}
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "stolencameras.props.country" | translate }}</label>
                        <ng-select
                            name="country"
                            [items]="countries"
                            bindLabel="name"
                            bindValue="code"
                            placeholder=""
                            [(ngModel)]="stolenCamera.country"
                            #country="ngModel"
                            [ngClass]="{ 'is-invalid': f.submitted && country.invalid }"
                            required
                        >
                        </ng-select>
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ "stolencameras.detail.validation.country" | translate }}
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "stolencameras.props.email" | translate }}</label>
                        <input name="email" type="text" class="form-control" [(ngModel)]="stolenCamera.email" #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ "stolencameras.detail.validation.email" | translate }}
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ "stolencameras.props.phone" | translate }}</label>
                        <input name="phone" type="text" class="form-control" [(ngModel)]="stolenCamera.phone" #phone="ngModel" [ngClass]="{ 'is-invalid': f.submitted && phone.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ "stolencameras.detail.validation.phone" | translate }}
                        </div>
                    </div>
                </div>

                <div class="col-md-6" *ngIf="MODE === 'EDIT'">
                    <div class="form-group">
                        <label>{{ "stolencameras.props.camowner" | translate }}</label>
                        <input name="camowner" type="text" class="form-control" [ngModel]="stolenCamera.getCamOwnerDetails()" readonly />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-6" *ngIf="MODE === 'EDIT'">
                    <div class="form-group">
                        <label>{{ "stolencameras.props.camera" | translate }}</label>
                        <input name="camera" type="text" class="form-control" [ngModel]="stolenCamera.getCameraDetails()" readonly />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="form-group">
                        <label>{{ "stolencameras.props.notes" | translate }}</label>
                        <textarea name="notes" type="text" class="form-control" [(ngModel)]="stolenCamera.notes" style="min-height: 225px"></textarea>
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-12">
                    <button class="float-right btn btn-success btn--icon-text" [disabled]="f.submitted && f.invalid"><i class="zmdi zmdi-check"></i> {{ "global.buttons.save" | translate }}</button>
                </div>
            </form>
        </div>
    </div>

    <div class="card" *ngIf="stolenCamera?.mailsSent && (stolenCamera?.mailsSent?.length ?? 0) > 0">
        <div class="card-body">
            <h3 class="card-body__title">{{ "stolencameras.props.mailsSent" | translate }}:</h3>

            <div class="row">
                <div class="col-md-12" *ngFor="let mailSent of stolenCamera?.mailsSent; let idx = index">
                    <div class="form-group">
                        <textarea name="mailSent_{{ idx }}" type="text" class="form-control" [ngModel]="mailSent" style="min-height: 225px" readonly="readonly"></textarea>
                        <i class="form-group__bar"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
