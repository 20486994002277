import { Component } from "@angular/core";
import { BaseComponent, IDatatableStateSaveableComponent } from "@impacgroup/angular-baselib";

@Component({
    selector: "app-stolencameras-main",
    templateUrl: "./stolencameras.main.component.html"
})
export class StolenCamerasMainComponent extends BaseComponent implements IDatatableStateSaveableComponent {
    public listStateData: any;
}
